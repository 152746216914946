import React, { useEffect, useRef, useState } from 'react'
import { CssBaseline, Box, Avatar, Button, Card, CardActions, CardContent, Divider, Stack, Typography, CardHeader, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import NavBar from '../../public_components/Layouts/NavBar';
import Footer from '../../public_components/Layouts/Footer';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import { useRole, useJwt } from '../../../utils/UserProvider';
import { useResponsiveMatch } from '../../../utils/useResponsiveMatch';
import { deleteUser, getAuthenticatedUser, updateUser, updateUserCredentials, verifyUserPasswordById } from '../../../services/userService';
import { capitalizeEachWord, getFirstLetterUpperCase, reassambleName } from '../../../utils/stringFunction';
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField';
import showAlert from '../../../utils/ShowAlert';
import PasswordInput from '../../public_components/Layouts/PasswordInput';
import { WarningAmber } from '@mui/icons-material';

const UserPage = ({ setLoading }) => {
    const { jwt } = useJwt();
    const [open, setOpen] = useState(false);
    const { role } = useRole();

    const [editable, setEditable] = useState(false);
    const userRef = useRef();

    const isMatch = useResponsiveMatch();

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        username: "",
        email: "",
        password: "",
    });

    const firstNameData = user.firstName;
    const lastNameData = user.lastName;

    const updateUserFields = (prop, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [prop]: value,
        }));
    }

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            if (userData !== null) {
                setUser(userData);
                userRef.current = userData;
            }
            setLoading(false);
        };
        if (jwt) {
            fetchUser();
        }
    }, [jwt, setLoading]);


    const handleReset = async () => {
        try {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            setUser(userData);
            // console.log(user);
            setEditable(false);
        }
        catch (error) {
            console.error("Error fetching user", error);
        }
        finally {
            setLoading(false);
        }
        setLoading(false);
    };

    const handleClickEditable = () => {
        if (editable) {
            setEditable(false);
            handleReset();
        }
        else {
            setEditable(true);
        }
    }

    const handleSubmit = async () => {
        console.log(user.phone.substring(5).length);
        setLoading(true);
        const savedUser = await updateUser(user, jwt);
        if (savedUser !== null) {
            setUser({ ...savedUser });
            setEditable(false);
        }
        setLoading(false);
    };

    const initialRequest = {
        username: '',
        email: '',
        password: '',
        newPassword: '',
        confirmNewPassword: '',
    };
    const [userRequest, setUserRequest] = useState(initialRequest);
    const [error, setError] = useState(initialRequest);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [verifiedPassword, setVerifiedPassword] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


    const handleClickUpdatePassword = () => {
        if (updatePassword) {
            setUpdatePassword(false);
        }
        else {
            setUpdatePassword(true);
        }
    }

    const verifyActualPassword = async () => {
        setLoading(true);
        const samePassword = await verifyUserPasswordById(user.id, userRequest, jwt);
        if (samePassword !== null) {
            if (samePassword) {
                setVerifiedPassword(true);
            }
            else {
                setVerifiedPassword(false);
                showAlert("Mot de passe incorrect !", 'error');
            }
        }
        setLoading(false);
    }

    const onInputChange = e => {
        const { name, value } = e.target;
        setUserRequest(prev => ({
            ...prev,
            [name]: value
        }));
        // validateInput(e);
    }

    // const validateInput = e => {
    //   let { name, value } = e.target;
    //   setError(prev => {
    //     const stateObj = { ...prev, [name]: "" };

    //     switch (name) {
    //       case "newPassword":
    //         if (!value) {
    //           stateObj[name] = "Veuillez entrer le mot de passe.";
    //         } else if (userRequest.confirmNewPassword && value !== userRequest.confirmNewPassword) {
    //           stateObj["confirmNewPassword"] = "Veuillez saisir le même mot de passe.";
    //         } else {
    //           stateObj["confirmNewPassword"] = userRequest.confirmNewPassword ? "" : error.confirmNewPassword;
    //         }
    //         break;

    //       case "confirmNewPassword":
    //         if (!value) {
    //           stateObj[name] = "Veuillez à nouveau entrer le mot de passe.";
    //         } else if (userRequest.newPassword && value !== userRequest.newPassword) {
    //           stateObj[name] = "Veuillez saisir le même mot de passe.";
    //         }
    //         break;

    //       default:
    //         break;
    //     }

    //     return stateObj;
    //   });
    // }

    const handleSubmitCredentials = async () => {
        setLoading(true);
        if (userRequest.confirmNewPassword !== userRequest.newPassword) {
            showAlert("Les mots de passe ne sont pas identiques!", 'error')
        }
        else {
            const request = {
                ...user,
                username: userRequest.username !== '' ? userRequest.username : user.username,
                email: userRequest.email !== '' ? userRequest.email : user.email,
                password: userRequest.newPassword,
            };
            console.log("user request " + request);
            const savedUser = await updateUserCredentials(request, jwt);
            console.log(savedUser)
            if (savedUser !== null) {
                setUser({ ...savedUser });
                localStorage.removeItem('jwt');
                window.location.href = '/';
            }
        }
        setLoading(false);
    };

    const handleResetCredentials = async () => {
        try {
            setLoading(true);
            const userData = await getAuthenticatedUser(jwt);
            setUser(userData);
            // console.log(user);
            setUpdatePassword(false);
            setLoading(false);
        }
        catch (error) {
            console.error("Error fetching user", error);
        }
        finally {
            setLoading(false);
        }
        setLoading(false);
    };


    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const handleDeleteAccount = async () => {
        try {
            setLoading(true);
            console.log("id", user.id);
            const response = await deleteUser(user.id, jwt);
            console.log(response);
            handleCloseDeleteDialog(false);
            localStorage.removeItem('jwt');
            window.location.href = '/';
            setLoading(false);
        }
        catch (error) {
            console.error("Error deleting user", error);
        }
        finally {
            setLoading(false);
        }
        setLoading(false);
    };

    const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
    const targetRefs = useRef([]); // Array of refs for all the elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute("data-index"), 10);
                        setVisibleItems((prevVisibleItems) => [
                            ...prevVisibleItems,
                            index,
                        ]);
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );
        // Observe all refs
        targetRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
        return () => {
            // Unobserve all refs
            targetRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: 'column' }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
                <DrawerHeader />
                <Box component="main" sx={{ p: 3, mt: isMatch ? 8 : 16, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    {userRef.current && (
                        <>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    px: 6,
                                    py: 2,
                                    marginBottom: 2,
                                    ...(isMatch && {
                                        marginTop: 8,
                                    })
                                }}
                            >
                                <CardContent
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                >
                                    <Stack spacing={2} sx={{ alignItems: 'center' }}>
                                        <div>
                                            <Avatar sx={{ bgcolor: 'var(--primary)', height: '80px', width: '80px' }}>
                                                {getFirstLetterUpperCase(firstNameData)}
                                                {getFirstLetterUpperCase(lastNameData)}
                                            </Avatar>
                                        </div>
                                        <Stack spacing={1} sx={{ textAlign: 'center' }}>
                                            <Typography variant="h5">
                                                {capitalizeEachWord(reassambleName(firstNameData, lastNameData))}
                                            </Typography>
                                            <Typography color="text.secondary" variant="body2">
                                                {user.username}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                                <Divider />
                                <CardActions
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'>
                                    <Button fullWidth variant="text" onClick={handleClickEditable}>
                                        {editable ? (
                                            <>
                                                Voir mes informations
                                            </>
                                        )
                                            :
                                            (
                                                <>
                                                    Editer mes informations
                                                </>
                                            )
                                        }
                                    </Button>
                                </CardActions>
                            </Card>

                            <Card
                                sx={{
                                    borderRadius: 3,
                                    py: 2,
                                    marginBottom: 2,
                                    minHeight: '400px',
                                }}
                                component='form'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                    handleReset();
                                }}
                            >
                                <CardHeader title="Profil"
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                />
                                <Divider />
                                <CardContent
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                >
                                    {!editable ?
                                        (
                                            <Stack spacing={2}>
                                                <Stack
                                                    spacing={{ xs: 1, sm: 2 }}
                                                    direction="column" flexWrap="wrap"
                                                >
                                                    <div>
                                                        <Typography color="text.secondary" variant='body2' >
                                                            Nom d'utilisateur
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {user.username}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography color="text.secondary" variant='body2' >
                                                            Nom
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            {lastNameData !== "" && capitalizeEachWord(lastNameData)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography color="text.secondary" variant='body2' >
                                                            Prénom
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {firstNameData !== "" && capitalizeEachWord(firstNameData)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography color="text.secondary" variant='body2' >
                                                            Adresse email
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {user.email}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography color="text.secondary" variant='body2' >
                                                            Téléphone
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {user.phone}
                                                        </Typography>
                                                    </div>
                                                </Stack>
                                            </Stack>
                                        )
                                        :
                                        (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="firstName"
                                                        name="firstName"
                                                        label="Prénom"
                                                        defaultValue={user.firstName}
                                                        onChange={(e) => updateUserFields("firstName", e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Nom"
                                                        defaultValue={user.lastName}
                                                        onChange={(e) => updateUserFields("lastName", e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PhoneNumberField
                                                        phone={user.phone}
                                                        update={updateUserFields}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </CardContent>
                                {
                                    editable && (
                                        <>
                                            <Divider sx={{ mt: 2 }} />
                                            <CardActions sx={{ justifyContent: 'flex-end' }}
                                                className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ mt: 2 }}
                                                >
                                                    Enregistrer
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    variant="outlined"
                                                    sx={{ ml: 4, mt: 2 }}
                                                >
                                                    Annuler
                                                </Button>
                                            </CardActions>
                                        </>
                                    )
                                }
                            </Card >

                            <Card
                                sx={{
                                    borderRadius: 3,
                                    py: 2,
                                    marginBottom: 2,
                                }}
                                component='form'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmitCredentials();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                    handleResetCredentials();
                                }}
                            >
                                <CardHeader title="Informations d'identification"
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                />
                                <Divider />
                                <CardContent
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                >
                                    <Stack spacing={3} sx={{ maxWidth: 'sm' }}>
                                        <TextField
                                            fullWidth
                                            name="username"
                                            label="Nom d'utilisateur"
                                            id="username"
                                            defaultValue={user.username}
                                            onChange={onInputChange}
                                            error={error.username !== ''}
                                            helperText={error.username !== '' && error.username}
                                        />
                                        <TextField
                                            disabled
                                            fullWidth
                                            name="email"
                                            label="Adresse email"
                                            type="email"
                                            id="email"
                                            defaultValue={user.email}
                                            onChange={onInputChange}
                                            error={error.email !== ''}
                                            helperText={error.email !== '' && error.email}
                                        />
                                        {!updatePassword ? (
                                            <Button
                                                variant='outlined' sx={{ p: 1 }}
                                                onClick={handleClickUpdatePassword}
                                            >
                                                Changer le mot de passe
                                            </Button>

                                        ) : (
                                            !verifiedPassword ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={9} >
                                                        <PasswordInput
                                                            password={userRequest.password}
                                                            handleChange={onInputChange}
                                                            name={"password"}
                                                            label={"Mot de passe actuel"}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} >
                                                        <Button
                                                            variant='outlined'
                                                            onClick={verifyActualPassword}
                                                            sx={{ p: 2 }}
                                                        >
                                                            Valider
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <>
                                                    {error.email && <Typography variant='body2' color='red'>{error.email}</Typography>}
                                                    <PasswordInput
                                                        password={userRequest.newPassword}
                                                        handleChange={onInputChange}
                                                        name={"newPassword"}
                                                        label={"Nouveau mot de passe"}
                                                        error={error}
                                                    />
                                                    <PasswordInput
                                                        password={userRequest.confirmNewPassword}
                                                        handleChange={onInputChange}
                                                        name={"confirmNewPassword"}
                                                        label={"Confirmer le nouveau mot de passe"}
                                                        error={error}
                                                    />
                                                </>
                                        )}
                                    </Stack>
                                </CardContent>
                                <Divider />
                                <CardActions sx={{ justifyContent: 'flex-end' }}
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                >
                                    <Button
                                        variant="contained"
                                        // disabled={userRequest.confirmNewPassword !== '' && error.confirmNewPassword !== ''}
                                        type='submit'
                                    >
                                        Enregistrer
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        type='reset'
                                    >
                                        Annuler
                                    </Button>
                                </CardActions>
                            </Card>

                            <Card
                                sx={{
                                    borderRadius: 3,
                                    py: 2,
                                    marginBottom: 2,
                                }}
                                component='form'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <CardHeader title="Supprimer mon compte"
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                />
                                <Divider />
                                <CardContent
                                    className='transition-opacity duration-300 ease-in-out animate-slide-in-up'
                                >
                                    <Button variant='outlined' color="error" onClick={handleOpenDeleteDialog} >
                                        Désactiver mon compte
                                    </Button>
                                </CardContent>
                            </Card >

                            {/* <Modal
                                open={openDeleteDialog}
                                onClose={handleCloseDeleteDialog}
                                aria-labelledby="modal-delete-account-label"
                                aria-describedby="modal-delete-account-description"
                            >
                                <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '50sv',
                                    borderRadius: 3,
                                    boxShadow: 24,
                                    p: 3
                                }}
                                component='form'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                >
                                <WarningAmber color='error' sx={{ width: '35px', height: '35px' }} />
                                <CardHeader title="Confirmer suppression de compte" />
                                <Divider />
                                <CardContent>
                                    <Typography color='GrayText' variant='body1' >
                                    Êtes-vous sûr de vouloir désactiver votre compte ? Toutes vos données seront définitivement supprimées. Cette action ne peut être annulée.
                                    </Typography>
                                </CardContent>
                                <Divider />
                                <CardActions sx={{ justifyContent: 'flex-end' }}>
                                </CardActions>
                                </Box >
                            </Modal> */}
                            <Dialog
                                open={openDeleteDialog}
                                onClose={handleCloseDeleteDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                component='form'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleDeleteAccount();
                                }}
                                className='transition-opacity duration-300 ease-in-out animate-fade-in'
                            >
                                <DialogTitle id="alert-dialog-title" sx={{ alignItems: 'center' }}>
                                    <WarningAmber color='error' sx={{ width: '35px', height: '35px', mr: 2 }} />
                                    Confirmer suppression de compte
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Êtes-vous sûr de vouloir désactiver votre compte ?
                                        {/* <br/>
                                            Toutes vos données seront définitivement supprimées.
                                            <br/>
                                            Cette action est irreversible. */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button color='inherit' onClick={handleCloseDeleteDialog}>Annuler</Button>
                                    <Button color='error' type='submit' autoFocus>
                                        Confirmer
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}

                </Box>
            </Box>
            {role === 'USER' && <Footer />}
        </Box>
    );
}

export default UserPage