import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FormControl, InputLabel, MenuItem, Select, Typography, Divider, CssBaseline, Box } from '@mui/material'
import { FormatQuoteOutlined, KeyboardArrowLeft, Sms } from '@mui/icons-material'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavBar from '../Layouts/NavBar'
import Footer from '../Layouts/Footer'
import Preloader from '../../Preloader/Preloader'
import { MainDrawer } from '../Layouts/Drawer';
import { useJwt, useRole } from '../../../utils/UserProvider';
import { getRelays } from '../../../services/relayService';
import { searchAds, getPublishedAds } from '../../../services/adService';
import { useCurrency } from '../../../utils/CurrencyProvider';

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <Button
      className={className}
      onClick={onClick}
      variant="contained"
      size='small'
      sx={{
        bgcolor: 'white',
        '&:hover': {
          bgcolor: 'white',
        },
        position: 'absolute',
        top: '50%',
        right: '-20px',
        transform: 'translateY(-50%)',
        zIndex: 1,
        pr: 3,
        py: 2,
      }}
    >
      <KeyboardArrowLeft
        sx={{
          transform: 'rotate(180deg)',
          color: 'black',
        }}
      />
    </Button>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <Button
      className={className}
      onClick={onClick}
      variant="contained"
      size='small'
      sx={{
        bgcolor: 'white',
        '&:hover': {
          bgcolor: 'white',
        },
        position: 'absolute',
        top: '50%',
        right: '-20px',
        transform: 'translateY(-50%)',
        zIndex: 1,
        pr: 3,
        py: 2,
      }}
    >
      <KeyboardArrowLeft
        sx={{
          color: 'black',
        }}
      />
    </Button>
  );
}

const HomePage = ({ setLoading }) => {
  const { role } = useRole();
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { jwt } = useJwt();

  const [open, setOpen] = useState(false);
  const [relays, setRelays] = useState([]);
  const [ads, setAds] = useState([]);

  const [adSearchRequest, setAdSearchRequest] = useState({
    type: '',
    departureRelay: null,
    arrivalRelay: null,
  })
  const updateAdSearch = (prop, value) => {
    setAdSearchRequest(prevAd => ({
      ...prevAd,
      [prop]: value
    }));
  }

  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      setRelays(relaysData);
      setLoading(false);
    };
    fetchRelays();
  }, [setLoading]);

  const handleSubmitSearchAds = async () => {
    setLoading(true);
    const ads = await searchAds(adSearchRequest);
    if (ads !== null) {
      const adsPublished = [];
      ads.forEach(ad => {
        if (ad.status === 'PUBLISHED') {
          adsPublished.push(ad);
        }
      });
      localStorage.setItem('adsSearch', JSON.stringify(adsPublished));
      window.location.href = `/ads?ads-data=${encodeURIComponent(adsPublished)}`;
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchAds = async () => {
      setLoading(true);
      const adsData = await getPublishedAds();
      if (adsData !== null)
        setAds(adsData);
      setLoading(false);
    };
    fetchAds();
  }, [setLoading]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3.5,
          initialSlide: 3.5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          initialSlide: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          initialSlide: 3,
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2.7,
          initialSlide: 2.7,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2.5,
          initialSlide: 2.5,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.2,
          initialSlide: 2.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1.5,
          initialSlide: 1.5
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.2,
          initialSlide: 1.2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        }
      }
    ],
  };

  const items = ads.map(ad =>
    <div key={ad.id}>
      <div className='cursor-pointer flex flex-col items-center bg-white rounded-lg 
            shadow-lg overflow-hidden w-[15rem] h-[20rem] mx-3'
        onClick={() => navigate(`/ads/${ad.id}`)}
      >
        <div className="relative w-[15rem] h-[13rem]">
          <img src={ad.picture}
            className='object-cover object-top w-full h-full' alt='Ad luggage' />
          <div className="bg-primary rounded text-white absolute  start-0 top-0 m-4 py-1 px-3">
            {ad.title}
          </div>
          {/* <div className="bg-white font-semibold rounded-t text-primary absolute start-0 bottom-0 mx-4 pt-1 px-3">
                {ad.weight} KG - {ad.price} MAD
              </div> */}
          <div className="flex flex-row space-x-3 align-center bg-white font-semibold rounded-t text-primary absolute start-0 bottom-0 mx-2 py-1 px-2">
            <span>{ad.weight} KG</span>
            <span>
              {currency === 'MAD' ?
                <>{ad.price} MAD</>
                :
                <>{ad.price * 60} Fcfa</>
              }
            </span>
            <span>
              <span className='text-sm text-primary'>{ad.offersNumber}</span>
              <Sms color='primary' sx={{ height: 20 }} />
            </span>
          </div>
        </div>
        <div className="p-4 w-[15rem]">
          {ad && ad.type === 'SALE' && (
            <>
              <p className='mt-2 text-sm'>
                <span>Délai de dépôt: </span>
                <span className='font-semibold'>{ad.depositDeadline}</span>
              </p>
              <p className='mt-2 text-sm'>
                <span>Date de départ: </span>
                <span className='font-semibold'>{ad.departureDate}</span>
              </p>
              <p className='mt-2 text-sm'>
                <span>Date d'arrivée: </span>
                <span className='font-semibold'>{ad.arrivalDate}</span>
              </p>
            </>
          )}
          {ad && ad.type === 'REQUEST' && (
            <>
              <p className='mt-2 text-sm'>
                <span>Voyage entre le: </span>
                <span className='font-semibold'>{ad.minDate}</span>
              </p>
              <p className='mt-2 text-sm'>
                <span>et le: </span>
                <span className='font-semibold'>{ad.maxDate}</span>
              </p>
              <p className='mt-2 text-sm'>
                <span>Nombre de bagage:  </span>
                <span className='font-semibold'>{ad.luggageNumber}</span>
              </p>
            </>

          )}
          <p className='mt-2 text-sm font-semibold'>
            {ad.departureRelay.city} - {ad.arrivalRelay.city}
          </p>
        </div>
      </div>
    </div>
  )

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        width: '100%',
        minWidth: '315px',
      }}
    >
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', width: '100%' }}>
        {(role === 'ADMIN' || role === 'COORDINATOR') &&
          <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
        }
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <div className="p-3 mt-24">
            {/* <DrawerHeader /> */}
            <div
              data-index={0}
              ref={(el) => (targetRefs.current[0] = el)}
              className={`bg-white p-0 mx-auto transition-opacity 
                ease-in-out transform ${visibleItems.includes(0)
                  ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <div className="row flex-col-reverse md:flex-row">
                <div className="md:col-12 p-5 lg:mt-5">
                  <Typography variant='h5' sx={{ mb: 2 }}>
                    Expédiez ou monétisez vos kilos d'avion <span className="text-primary">simplement !</span>
                  </Typography>
                  <Typography variant='body1' sx={{ mb: 2 }}>
                    Avec SendNous, vous pouvez vendre ou acheter des kilos de bagages d'avion inutilisés en provenance ou à destination du Maroc et/ou du Cameroun. <br />
                    Connectez-vous et créez vos annonces en quelques clics. C'est simple, rapide et économique.
                    {/* Avec SendNous, vous pouvez envoyer vos bagages par avion ou vendre vos kilos de bagages disponibles. C'est simple, rapide et économique. */}
                  </Typography>
                  {/* <h1 className='text-3xl font-semibold mb-4'>Vous souhaitez envoyer des bagages ou vendre vos kilos de bagages <span className="text-primary">simplement ?</span></h1> */}
                  {/* <p className="mb-4 pb-2">Avec SendNous, vous pouvez envoyer vos bagages par avion ou vendre vos kilos de bagages disponibles. C'est simple, rapide et économique. </p> */}
                </div>
              </div>
              <div className="md:flex md:flex-row md:flex-1">
                <div className="md:flex-1 m-5 mt-0">
                  <video className="w-full h-full rounded" controls autoPlay='' poster={process.env.PUBLIC_URL + "/logo.png"}>
                    <source
                      // src="https://docs.material-tailwind.com/demo.mp4" 
                      src={process.env.PUBLIC_URL + "/video_home.mp4"}
                      type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="md:flex-1 bg-white rounded mx-5 p-7 shadow-lg border">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmitSearchAds();
                    }}
                    className="flex flex-col justify-between h-full"
                  >
                    <div className="mb-5">
                      <FormControl fullWidth>
                        <InputLabel id="type-label">Type de l'annonce</InputLabel>
                        <Select
                          labelId="type-label"
                          id="type"
                          label="Type de l'annonce"
                          value={adSearchRequest.type}
                          onChange={(e) => updateAdSearch("type", e.target.value)}
                        >
                          {[
                            <MenuItem key={1} value={'SALE'}>
                              Vente de kilos
                            </MenuItem>
                            ,
                            <MenuItem key={2} value={'REQUEST'}>
                              Demande d'achat de kilos
                            </MenuItem>
                          ]}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mb-5">
                      <FormControl fullWidth>
                        <InputLabel id="departure-relay-label">Lieu de départ</InputLabel>
                        <Select
                          labelId="departure-relay"
                          id="departureRelay"
                          value={adSearchRequest.departureRelay ? adSearchRequest.departureRelay : ''}
                          label="Lieu de dépôt"
                          onChange={(e) => updateAdSearch("departureRelay", e.target.value)}
                        >
                          {relays && relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                              {relay.city}, {relay.country}
                            </MenuItem>
                          ])}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mb-5">
                      <FormControl fullWidth>
                        <InputLabel id="arrival-relay-label">Lieu d'arrivée</InputLabel>
                        <Select
                          labelId="arrival-relay"
                          id="arrivalRelay"
                          value={adSearchRequest.arrivalRelay ? adSearchRequest.arrivalRelay : ''}
                          label="Lieu de retrait"
                          onChange={(e) => updateAdSearch("arrivalRelay", e.target.value)}
                        >
                          {relays && relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                              {relay.city}, {relay.country}
                            </MenuItem>
                          ])}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <Button variant='contained' type='submit'
                        fullWidth sx={{ p: 1.5, fontWeight: 'bold', }}
                      >
                        Rechercher
                      </Button>
                    </div>
                    <div>
                      <Typography variant='body1' color='GrayText' align='center' sx={{ my: 2 }}>
                        Ou
                      </Typography>
                      <Button variant='outlined' onClick={() => jwt ? navigate('/user/ads/published') : navigate('/open-auth?url=/')}
                        fullWidth sx={{ p: 1.5, fontWeight: 'bold', }}
                      >
                        Créer une anonce
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div >
            <Divider sx={{ marginY: 5 }} />
            <div
              data-index={1}
              ref={(el) => (targetRefs.current[1] = el)}
              className={`flex flex-col items-center justify-center mx-auto transition-opacity 
                ease-in-out transform ${visibleItems.includes(1)
                  ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Typography variant="h4" sx={{ mb: 2 }}>
                Annonces
              </Typography>
              {ads.length > 0 ? (
                <div className='w-[75vw] m-auto justify-center align-center'>
                  <div className='mt-5'>
                    <Slider {...settings}>
                      {items}
                    </Slider>
                  </div>
                </div>
              ) : (
                <Preloader />
              )}
              <Button
                variant='outlined'
                onClick={() => navigate('/ads')}
                sx={{ mt: 5 }}
              >
                Voir les annonces
              </Button>
            </div>
            <Divider sx={{ marginY: 5 }} />
            <Box
              data-index={2}
              ref={(el) => (targetRefs.current[2] = el)}
              className={`mx-auto transition-opacityease-in-out transform 
                ${visibleItems.includes(2) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Box sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ mb: 5, textAlign: 'center' }}>
                  Ce que nos clients disent de nous
                </Typography>
              </Box>
              <Box sx={{ mx: 'auto', display: 'grid', gridTemplateColumns: { lg: '1fr 1fr' }, gap: { lg: 20, xs: 1 }, px: { md: 10 }, mb: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: { xs: 3, lg: 0 } }}>
                  <Box sx={{ position: 'relative', textAlign: 'center' }}>
                    <FormatQuoteOutlined sx={{ position: 'absolute', top: 0, left: 0, width: 32, height: 32, color: 'action.active' }} />
                    <Typography variant='body1' sx={{ px: 5, py: 1, fontStyle: 'italic' }}>
                      Super... Mon frère a reçu tous mes colis intacts en un temps record... C'est trop fort. Merci SendNous.
                    </Typography>
                    <FormatQuoteOutlined sx={{ position: 'absolute', bottom: 0, right: 0, width: 32, height: 32, color: 'action.active' }} />
                  </Box>
                  <Box sx={{ width: 48, height: 4, my: 2, backgroundColor: 'primary.main', borderRadius: 1 }} />
                  <Typography>Atangana Loïc</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: { xs: 3, lg: 0 } }}>
                  <Box sx={{ position: 'relative', textAlign: 'center' }}>
                    <FormatQuoteOutlined sx={{ position: 'absolute', top: 0, left: 0, width: 32, height: 32, color: 'action.active' }} />
                    <Typography variant='body1' sx={{ px: 5, py: 1, fontStyle: 'italic' }}>
                      Service de qualité, l'application fonctionne bien et les personnes qui assurent le relai ont été très serviables... Je recommande vivement !!!
                    </Typography>
                    <FormatQuoteOutlined sx={{ position: 'absolute', bottom: 0, right: 0, width: 32, height: 32, color: 'action.active' }} />
                  </Box>
                  <Box sx={{ width: 48, height: 4, my: 2, backgroundColor: 'primary.main', borderRadius: 1 }} />
                  <Typography>Iya Idrissa Mamat</Typography>
                </Box>
              </Box>
            </Box>
          </div>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage