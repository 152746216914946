import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react'
import { status_array } from '../../../data/adData';

const AdUserSearchForm = ({ adSearchRequest, updateAdSearch, relays, handleResetSearch }) => {
    return (
        <form
            onReset={(e) => {
                e.preventDefault();
                handleResetSearch();
            }}
        >
            <div className="mb-5">
                <FormControl fullWidth>
                    <FormLabel id="type-label">Type d'annonce</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="type-label"
                        value={adSearchRequest.type}
                        onChange={(e) => updateAdSearch("type", e.target.value)}
                    >
                        <FormControlLabel
                            key={0}
                            label="Vente"
                            value={'SALE'}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            key={1}
                            label="Demande"
                            value={'REQUEST'}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="mb-5">
                <FormControl fullWidth size="small">
                    <FormLabel id="status-label">Statut</FormLabel>
                    <Select
                        labelId="status-label"
                        id="status"
                        displayEmpty
                        variant='standard'
                        value={adSearchRequest.status ? adSearchRequest.status : ''}
                        onChange={(e) => updateAdSearch("status", e.target.value)}
                    >
                        {[
                            <MenuItem key={0} value={''} hidden>
                                <span style={{ color: grey[500] }}>Statut</span>
                            </MenuItem>
                        ]}
                        {status_array.map((status, index) => [
                            <MenuItem key={index} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ])}
                    </Select>
                </FormControl>
            </div>
            <div className="mb-5">
                <FormControl fullWidth size="small">
                    <FormLabel id="departure-relay-label">Départ</FormLabel>
                    <Select
                        labelId="departure-relay-label"
                        id="departureRelay"
                        displayEmpty
                        variant='standard'
                        value={adSearchRequest.departureRelay ? adSearchRequest.departureRelay : undefined}
                        onChange={(e) => updateAdSearch("departureRelay", e.target.value)}
                    >
                        {[
                            <MenuItem key={0} value={undefined} hidden>
                                <span style={{ color: grey[500] }}>Lieu de départ</span>
                            </MenuItem>
                        ]}
                        {relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                                {relay.city}, {relay.country}
                            </MenuItem>
                        ])}
                    </Select>
                </FormControl>
            </div>
            <div className="mb-5">
                <FormControl fullWidth size="small">
                    <FormLabel id="arrival-relay-label">Arrivée</FormLabel>
                    <Select
                        labelId="arrival-relay-label"
                        id="arrivalRelay"
                        displayEmpty
                        variant='standard'
                        value={adSearchRequest.arrivalRelay ? adSearchRequest.arrivalRelay : undefined}
                        onChange={(e) => updateAdSearch("arrivalRelay", e.target.value)}
                    >
                        {[
                            <MenuItem value={undefined} key={0} hidden>
                                <span style={{ color: grey[500] }}>Lieu d'arrivée</span>
                            </MenuItem>
                        ]}
                        {relays.map((relay) => [
                            <MenuItem key={relay.id} value={relay}>
                                {relay.city}, {relay.country}
                            </MenuItem>
                        ])}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button variant='outlined' size='small' type='reset'
                    fullWidth sx={{ my: 2, p: 1, fontWeight: 'bold', }}
                >
                    Effacer le filtre
                </Button>
            </div>
        </form>
    )
}

export default AdUserSearchForm
