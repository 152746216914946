import ajax from "./ajax";

export const saveRelay = async (request, jwt) => {
  return ajax("/coordinator/relays/create", "POST", jwt, request);
};

export const getRelays = async () => {
  return ajax("/api/relays", "GET");
};

export const getAllRelays = async (jwt) => {
  return ajax("/coordinator/relays", "GET", jwt);
};
 
export const searchRelays = async (request, jwt) => {
  const requestBody = {
    country: request.country,
    city: request.city,
  };

  return ajax("/user/relays", "POST", jwt, requestBody);
};

export const getRelayById = async (relayId, jwt) => {
  return ajax(`/user/relays/${relayId}`, "GET", jwt);
};

export const updateRelay = async (request, jwt) => {
  return ajax(`/coordinator/relays/${request.id}`, "PUT", jwt, request);
};

export const reactivateRelay = async (relayId, jwt) => {
  return ajax(`/coordinator/relays/reactivate/${relayId}`, "POST", jwt);
};

export const getRelayToDelete = async (relayId, jwt) => {
  return ajax(`/coordinator/relays/deleted/${relayId}`, "GET", jwt);
};

export const deleteRelay = async (relayId, jwt) => {
  return ajax(`/coordinator/relays/${relayId}`, "DELETE", jwt);
};
