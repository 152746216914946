import React, { useEffect, useRef, useState } from 'react'
import { Button, Box, Card, CardContent, CardHeader, Chip, CssBaseline, Divider, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Stack } from '@mui/material'
import Footer from '../../public_components/Layouts/Footer'
import { getAdById, updateAdProperties } from '../../../services/adService'
import { CalendarToday, Cancel, CheckCircle, DateRange, Scale, Sms, Today } from '@mui/icons-material'
import dayjs from 'dayjs'
import { statusColor, statusLabel } from '../../../data/adData'
import AdEditDialogUser from './AdEditDialog'
import { useRole } from '../../../utils/UserProvider'
import AdEditDialog from '../../coordinator_components/AdCrud/AdEditDialog'
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from '../../public_components/Layouts/NavBar';
import { getOfferById, getOffersByAd, updateOfferStatus } from '../../../services/offerService'
import { useJwt } from '../../../utils/UserProvider';
import { useCurrency } from '../../../utils/CurrencyProvider'

const AdView = ({ setLoading }) => {
    const { jwt } = useJwt();
    const [open, setOpen] = useState(false);
    const { role } = useRole();
    const { currency } = useCurrency();

    const adId = window.location.href.split("/user/ads/")[1];
    const url = '/user/ads/' + adId;
    const adIdRef = useRef();

    const [ad, setAd] = useState({
        title: '',
        type: '',
        picture: '',
        weight: 23,
        price: 1400,
        note: '',
        departureRelay: null,
        arrivalRelay: null,
        depositDeadline: dayjs('', "DD-MM-YYYY HH:mm"),
        departureDate: '',
        arrivalDate: '',
        stopoversDetails: '',
        luggageNumber: 0,
        luggageDescription: '',
        minDate: '',
        maxDate: '',
        adContentIds: [],
    });
    const [offers, setOffers] = useState([]);
    const [openUpdateAd, setOpenUpdateAd] = useState(false);

    useEffect(() => {
        const fetchAd = async () => {
            setLoading(true);
            const adData = await getAdById(adId);
            if (adData !== null) {
                setAd(adData);
            }
            setLoading(false);
        };

        if (adId) {
            fetchAd();
        }

    }, [adId, setLoading]);


    useEffect(() => {
        const fetchOffers = async () => {
            setLoading(true);
            const responseData = await getOffersByAd(adId);
            if (responseData !== null) {
                setOffers(responseData);
            }
            setLoading(false);
        };
        if (adId) {
            fetchOffers();
        }
    }, [adId, setLoading]);

    const handleClickUpdateAd = (id) => {
        adIdRef.current = id;
        setOpenUpdateAd(true);
    };

    const handleAcceptOffer = async (id) => {
        setLoading(true);
        const offer = await getOfferById(id, jwt);
        var newOffers = offers;
        if (offer !== null && offers.length > 0) {
            newOffers = offers.map(async (o) => {
                if (offer.bidder.id !== o.bidder.id)
                    o.offerStatus = 'REFUSED';
                const responseData = await updateOfferStatus(o, jwt);
                if (responseData !== null) {
                    return o;
                }
                else {
                    return null;
                }
            })
            if (!newOffers.includes(null)) {
                offer.offerStatus = 'ACCEPTED';
                const responseData = await updateOfferStatus(offer, jwt);
                if (responseData !== null) {
                    const adResponse = await updateAdProperties(ad.id, { 'status': 'RESPONDED', 'customer': offer.bidder }, jwt);
                    if (adResponse !== null) {
                        setOffers(newOffers);
                        setAd({ ...adResponse });
                    }
                }
            }
        }
        setLoading(false);
    }

    const handleRejectOffer = async (id) => {
        setLoading(true);
        const offer = await getOfferById(id, jwt);
        if (offer !== null) {
            offer.offerStatus = 'REFUSED';
            const responseData = updateOfferStatus(offer, jwt);
            if (responseData !== null) {
                setOffers(prev => {
                    const index = prev.findIndex((item) => item.id === offer.id);
                    prev = prev.map((o, i) => {
                        if (i === index)
                            return offer;
                        else
                            return o;
                    })
                    return prev;
                })
            }
        }
        setLoading(false);
    }

    const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
    const targetRefs = useRef([]); // Array of refs for all the elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute("data-index"), 10);
                        setVisibleItems((prevVisibleItems) => [
                            ...prevVisibleItems,
                            index,
                        ]);
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );
        // Observe all refs
        targetRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
        return () => {
            // Unobserve all refs
            targetRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 8 }}>
                    <DrawerHeader />
                    <Grid item container xs={12}>
                        <Grid item xs={12}
                            data-index={0}
                            ref={(el) => (targetRefs.current[0] = el)}
                            className={`transition-opacity ease-in-out transform ${visibleItems.includes(0)
                                ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                }`}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='h5' className="px-5" >
                                    {role !== 'USER' ? `Détails de l'annonce ${ad.id}` : `Détails de l'annonce`}
                                </Typography>
                            </Box>
                            <nav aria-label="Breadcrumb">
                                <ol className="flex max-w-2xl space-x-2 pt-4 md:pt-2 px-5 mb-5 lg:max-w-7xl">
                                    <li key={1}>
                                        <div className="flex">
                                            <a href={role && role === 'USER' ? '/user/ads/published' : '/ads/manage'} className="mr-2 border-b">
                                                Gestion des Annonces
                                            </a>
                                            <span className='text-gray-400'>{`/`}</span>
                                        </div>
                                    </li>
                                    <li key={2}>
                                        <div className="flex">
                                            <span className="text-gray-500 hover:text-gray-600">
                                                Détails
                                            </span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </Grid>
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    m: 2,
                                }}
                                data-index={1}
                                ref={(el) => (targetRefs.current[1] = el)}
                                className={`transition-opacity ease-in-out transform ${visibleItems.includes(1)
                                    ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                    }`}
                            >
                                <CardContent>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs={12} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <Typography variant='h6' component='span'>
                                                Titre
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                {ad.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider orientation="horizontal" flexItem />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <img className='w-full h-[8rem] object-cover object-top'
                                                src={ad.picture} alt='' />
                                            {(!ad.title.toLowerCase().includes('vente') && !ad.title.toLowerCase().includes('demande')) &&
                                                <Typography variant='h6' component='h6'>
                                                    {ad.type === 'SALE' ? `Kilos en Vente` : `Demande d'achat de Kilos`}
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            {((ad.status === 'PENDING_VALIDATION' && role === 'USER')
                                                || (ad.status !== 'CLOSED' && role !== 'USER'))
                                                &&
                                                <Button variant='contained'
                                                    onClick={() => {
                                                        handleClickUpdateAd(ad.id)
                                                    }}
                                                >
                                                    Modifier
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            {ad.status &&
                                                <Chip
                                                    label={statusLabel(ad.status)}
                                                    variant="outlined"
                                                    color={statusColor(ad.status)}
                                                    size='medium'
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" sx={{ color: "green", mr: 1, fontWeight: 'bold' }}>
                                                    {currency === 'MAD' ? 'MAD' : 'FCFA'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {currency === 'MAD' ? ad.price : ad.price * 60}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Scale fontSize="small" sx={{ color: "var(--secondary)", mr: 1 }} />
                                                <Typography variant="body2">{ad.weight} kg</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <div>
                                                <CalendarToday color='primary' sx={{ height: 20 }} />
                                                <span className='text-sm'>Créée le {ad.creationDate}</span>
                                            </div>
                                            {ad.status !== 'PENDING_VALIDATION' && ad.status !== 'CANCELLED' &&
                                                <div>
                                                    <Today color='primary' sx={{ height: 20 }} />
                                                    <span className='text-sm'>Publiée le {ad.publicationDate && dayjs(ad.publicationDate).format('DD-MM-YYYY HH:MM')}</span>
                                                </div>
                                            }
                                            <div>
                                                <Sms color='primary' sx={{ height: 20 }} />
                                                <span className='text-sm'>{ad.offersNumber} réponses</span>
                                            </div>
                                        </Grid>
                                        {role !== 'USER' &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Divider orientation="horizontal" flexItem />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                        Annonceur
                                                    </Typography>
                                                    {ad.publisher &&
                                                        <div>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Nom d'utilisateur</span>
                                                                {ad.publisher.username}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Nom & Prénom</span>
                                                                {ad.publisher.lastName} {ad.publisher.firstName}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Téléphone</span>
                                                                {ad.publisher.phone}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Email</span>
                                                                {ad.publisher.email}
                                                            </Typography>

                                                        </div>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                        Client
                                                    </Typography>
                                                    {ad.customer &&
                                                        <div>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Nom d'utilisateur</span>
                                                                {ad.customer.username}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Nom & Prénom</span>
                                                                {ad.customer.lastName} {ad.customer.firstName}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Téléphone</span>
                                                                {ad.customer.phone}
                                                            </Typography>
                                                            <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                <span className='text-sm text-gray-600'>Email</span>
                                                                {ad.customer.email}
                                                            </Typography>

                                                        </div>
                                                    }
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12}>
                                            <Divider orientation="horizontal" flexItem />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                Lieu de dépôt
                                            </Typography>
                                            {ad.departureRelay &&
                                                <div>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Pays</span>
                                                        {ad.departureRelay.country}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Ville</span>
                                                        {ad.departureRelay.city}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Addresse</span>
                                                        {ad.departureRelay.address}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Téléphone</span>
                                                        {ad.departureRelay.phone}
                                                    </Typography>

                                                </div>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                Lieu de retrait
                                            </Typography>
                                            {ad.departureRelay &&
                                                <div>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Pays</span>
                                                        {ad.arrivalRelay.country}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Ville</span>
                                                        {ad.arrivalRelay.city}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Addresse</span>
                                                        {ad.arrivalRelay.address}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Téléphone</span>
                                                        {ad.arrivalRelay.phone}
                                                    </Typography>

                                                </div>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider orientation="horizontal" flexItem />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                Dates <DateRange sx={{ height: 20 }} color='primary' />
                                            </Typography>
                                            {ad.type === 'REQUEST' &&
                                                <>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Minimum</span>
                                                        {ad.minDate}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Maximum</span>
                                                        {ad.maxDate}
                                                    </Typography>
                                                </>
                                            }
                                            {ad.type === 'SALE' &&
                                                <>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Départ</span>
                                                        {ad.departureDate}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Arrivée</span>
                                                        {ad.arrivalDate}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Délai de dépot au relai</span>
                                                        {ad.depositDeadline}
                                                    </Typography>
                                                </>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                Bagages
                                            </Typography>
                                            {ad.type === 'REQUEST' &&
                                                <>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Nombre</span>
                                                        {ad.luggageNumber}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Description</span>
                                                        {ad.luggageDescription}
                                                    </Typography>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Contenu des bagages</span>
                                                        {ad.adContents && ad.adContents.map((content) =>
                                                            <span key={content.id}>
                                                                {content.designation}
                                                                {ad.adContents[ad.adContents.length - 1].id !== content.id && `, `}
                                                            </span>
                                                        )}
                                                    </Typography>
                                                </>
                                            }
                                            {ad.type === 'SALE' &&
                                                <>
                                                    <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <span className='text-sm text-gray-600'>Nombre max de bagages</span>
                                                        {ad.luggageNumber}
                                                    </Typography>

                                                    {ad.stopoversDetails &&
                                                        <>
                                                            <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                                Escales
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {ad.stopoversDetails}
                                                            </Typography>
                                                        </>
                                                    }
                                                </>

                                            }
                                        </Grid>
                                        {ad.note &&
                                            [
                                                <Grid item xs={12}>
                                                    <Divider orientation="horizontal" />
                                                </Grid>
                                                ,
                                                <Grid item xs={12} >
                                                    <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                        Note
                                                    </Typography>
                                                    <Typography variant='body2' component='span' color='GrayText'>{ad.note}</Typography>
                                                </Grid>

                                            ]
                                        }
                                        <Grid item xs={12}>
                                            <Divider orientation="horizontal" />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography sx={{ my: 2 }} variant="h5" component="div">
                                                Réponses
                                            </Typography>
                                            <Table sx={{ maxWidth: 550 }} aria-label="List of offers">
                                                <TableHead>
                                                    <TableRow key={0}>
                                                        {ad.type === 'SALE' &&
                                                            [
                                                                <TableCell key={1}>Nombre de bagages</TableCell>
                                                                ,
                                                                <TableCell key={2}>Description</TableCell>
                                                                ,
                                                                <TableCell key={3}>Contenus</TableCell>

                                                            ]
                                                        }
                                                        {ad.type === 'REQUEST' &&
                                                            [
                                                                <TableCell key={4}>Départ</TableCell>
                                                                ,
                                                                <TableCell key={5}>Arrivée</TableCell>
                                                                ,
                                                                <TableCell key={6}>Délai de dépôt</TableCell>
                                                            ]
                                                        }
                                                        {ad.status === 'PUBLISHED' &&
                                                            <TableCell key={7}>Actions</TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {offers &&
                                                        (offers.length === 0 ?
                                                            <TableRow key={0}>
                                                                <TableCell key={1} colSpan={7} align='center' height={100}>
                                                                    <Typography variant='body2' component='span' color='GrayText'>
                                                                        Aucune réponse
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            offers.map((offer) => {
                                                                return (
                                                                    <TableRow
                                                                        key={offer.id}
                                                                        sx={{
                                                                            backgroundColor: offer.offerStatus === 'ACCEPTED' && '#c8e6c9',
                                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                                        }}
                                                                    >
                                                                        {ad.type === 'SALE' &&
                                                                            [
                                                                                <TableCell key={1}>
                                                                                    {offer.luggageNumber}
                                                                                </TableCell>
                                                                                ,
                                                                                <TableCell key={2}>
                                                                                    {offer.luggageDescription}
                                                                                </TableCell>
                                                                                ,
                                                                                <TableCell key={3}>
                                                                                    <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                                                        {offer.offerContents && offer.offerContents.map((content) =>
                                                                                            <Typography key={content.id} variant='body2' component='span'>
                                                                                                {content.designation}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Stack>
                                                                                </TableCell>

                                                                            ]
                                                                        }
                                                                        {ad.type === 'REQUEST' &&
                                                                            [
                                                                                <TableCell key={4}>
                                                                                    {offer.departureDate}
                                                                                </TableCell>
                                                                                ,
                                                                                <TableCell key={5}>
                                                                                    {offer.arrivalDate}
                                                                                </TableCell>
                                                                                ,
                                                                                <TableCell key={6}>
                                                                                    {offer.depositDeadline}
                                                                                </TableCell>
                                                                            ]
                                                                        }
                                                                        <TableCell key={7}>
                                                                            {offer.offerStatus === 'PENDING_RESPONSE' &&
                                                                                [

                                                                                    <IconButton edge="end" aria-label="Modifier"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            handleAcceptOffer(offer.id);
                                                                                        }}
                                                                                        sx={{ mr: 0.25 }}
                                                                                    >
                                                                                        <CheckCircle color='success' />
                                                                                    </IconButton>
                                                                                    ,
                                                                                    <IconButton edge="end" aria-label="Supprimer" color='error'
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            handleRejectOffer(offer.id);
                                                                                        }}
                                                                                    >
                                                                                        <Cancel color='error' />
                                                                                    </IconButton>
                                                                                ]
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card >
                        </Grid>
                    </Grid>
                </Box>
                {adIdRef.current &&
                    role === 'USER' ?
                    <AdEditDialogUser setLoading={setLoading} adId={adIdRef.current} ad={ad} setAd={setAd} url={url}
                        openUpdateAd={openUpdateAd} setOpenUpdateAd={setOpenUpdateAd} />
                    :
                    <AdEditDialog setLoading={setLoading} adId={adIdRef.current} url={url}
                        openUpdateAd={openUpdateAd} setOpenUpdateAd={setOpenUpdateAd} />

                }
                {role !== 'ADMIN' && role !== 'COORDINATOR' && <Footer />}
            </Box>
        </Box>
    );
}

export default AdView