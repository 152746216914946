export const status_array = [
  { value: "PENDING_VALIDATION", label: "En attente de validation" },
  { value: "PUBLISHED", label: "Publiée" },
  { value: "RESPONDED", label: "Répondue" },
  { value: "IN_DEPARTURE", label: "Au relai départ" },
  { value: "ON_THE_WAY", label: "En chemin" },
  { value: "IN_ARRIVAL", label: "Au relai arrivée" },
  { value: "CLOSED", label: "Clôturée" },
  { value: "CANCELLED", label: "Annulée" },
];

export const adStatusName = {
  PENDING_VALIDATION: "En attente de validation",
  PUBLISHED: "Publiée",
  IN_TRANSACTION: "En transaction",
  IN_DEPART_RELAY: "Au relai départ",
  IN_FLIGHT: "En chemin",
  IN_ARRIVAL_RELAY: "Au relai arrivée",
  CLOSED: "Clôturée",
  CANCELLED: "Annulée",
};

export const adTypeName = [{ SALE: "Vente" }, { REQUEST: "Demande" }];

export const adTypes = [
  { label: "Vente", value: "SALE" },
  { label: "Demande", value: "REQUEST" },
];

export function typeLabel(type) {
  let type_label;
  switch (type) {
    case "SALE":
      type_label = "Vente";
      break;
    case "REQUEST":
      type_label = "Demande";
      break;
    default:
      type_label = "";
      break;
  }
  return type_label;
}

export function statusLabel(status) {
  const statusObject = status_array.find((s) => s.value === status);
  const status_label = statusObject ? statusObject.label : "";
  return status_label;
}

export function statusColor(status) {
  let status_color;
  switch (status) {
    case status_array.at(0).value:
      status_color = "default";
      break;
    case status_array.at(1).value:
      status_color = "primary";
      break;
    case status_array.at(2).value:
      status_color = "info";
      break;
    case status_array.at(3).value:
      status_color = "warning";
      break;
    case status_array.at(4).value:
      status_color = "default";
      break;
    case status_array.at(5).value:
      status_color = "warning";
      break;
    case status_array.at(6).value:
      status_color = "success";
      break;
    case status_array.at(7).value:
      status_color = "error";
      break;
    default:
      status_color = "default";
      break;
  }
  return status_color;
}

// const request = {
//     id: ad.id,
//     title: ad.title,
//     type: ad.type,
//     picture: ad.picture,
//     weight: ad.weight,
//     price: ad.price,
//     depositDeadline: dayjs(ad.depositDeadline, "DD-MM-YYYY HH:mm"),
//     departureDate: ad.departureDate,
//     arrivalDate: ad.arrivalDate,
//     note: ad.note,
//     stopoversDetails: ad.stopoversDetails,
//     departureRelay: ad.departureRelay,
//     arrivalRelay: ad.arrivalRelay,
// }
