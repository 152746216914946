import React, { useEffect, useRef, useState } from 'react'

const Footer = () => {

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div
      data-index={0}
      ref={(el) => (targetRefs.current[0] = el)}
      className={`bottom-0 w-full flex flex-col items-center justify-center 
        p-2 mt-10 bg-black text-white text-center transition-opacity ease-in-out transform 
        ${visibleItems.includes(0) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
      }`}
    >
      <div>
        <img alt='SendNous logo' className='m-auto h-30 w-40'
          src={process.env.PUBLIC_URL + "/logo.png"}
        />
      </div>
      <p className="font-bold">
        Économiser et gagner de l'argent avec la meilleure
        application pour l'échange de kilos de bagages d'avion.
      </p>
      <p>Copyright © 2024 - Tous droits réservés</p>
    </div>
  )
}

export default Footer