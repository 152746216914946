import ajax from "./ajax";

export const createOffer = async (request, jwt) => {
  return ajax(`/user/offers/create`, "POST", jwt, request);
};

export const getOffers = async (jwt) => {
  return ajax(`/coordinator/offers`, "GET", jwt);
}; 

export const getOffersByBidder = async (jwt) => {
  return ajax(`/user/offers`, "GET", jwt);
};

export const searchOffers = async (request, jwt) => {
  return ajax(`/user/offers`, "POST", jwt, request);
};

export const searchUserOffers = async (offer, jwt) => {
  const requestBody = {
    status_name: offer.status,
    type_name: offer.type,
    departureRelay: offer.ad.departureRelay,
    arrivalRelay: offer.ad.arrivalRelay,
    bidder: offer.bidder,
  };
  return ajax("/user/ads", "POST", jwt, requestBody);
};

export const getOfferById = async (offerId, jwt) => {
  return ajax(`/user/offers/${offerId}`, "GET", jwt);
};

export const getBiddersByAd = async (adId, jwt) => {
  return ajax(`/coordinator/bidders/ad/${adId}`, "GET", jwt);
};

export const getOffersByAd = async (adId) => {
  return ajax(`/api/offers/ad/${adId}`, "GET");
};

export const updateOffer = async (request, jwt) => {
  return ajax(`/user/offers/${request.id}`, "PUT", jwt, request);
};

export const updateOfferStatus = async (request, jwt) => {
  return ajax(`/user/offers/status/${request.id}`, "PUT", jwt, request);
};

export const deleteOffer = async (offerId, jwt) => {
  return ajax(`/user/offers/${offerId}`, "DELETE", jwt);
};
