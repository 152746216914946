import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import React, { useState } from 'react'

const SortAds = ({ setLoading, adsSearched, setAdsSearched, ads, setAds }) => {
    const theme = useTheme();
    
    const [anchorEl, setAnchorEl] = useState(null);
    const openSortMenu = Boolean(anchorEl);
    const handleSortClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSortMenu = (event) => {
        setAnchorEl(null);
    };

    const handleSortAdsAscending = () => {
        setLoading(true);
        if (adsSearched && adsSearched.length > 0) {
            const sortedAds = [...adsSearched].sort((a, b) => new Date(a.publicationDate) - new Date(b.publicationDate));
            setAdsSearched(sortedAds);
        } else {
            const sortedAds = [...ads].sort((a, b) => new Date(a.publicationDate) - new Date(b.publicationDate));
            setAds(sortedAds);
        }
        handleCloseSortMenu();
        setLoading(false);
    };

    const handleSortAdsDescending = () => {
        setLoading(true);
        if (adsSearched && adsSearched.length > 0) {
            const sortedAds = [...adsSearched].sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
            setAdsSearched(sortedAds);
        } else {
            const sortedAds = [...ads].sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
            setAds(sortedAds);
        }
        handleCloseSortMenu();
        setLoading(false);
    };
    return [
        <Button
            onClick={handleSortClick}
            sx={{
                color: 'GrayText',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
        >
            Trier
            <KeyboardArrowDown
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 
                                      text-gray-400 group-hover:text-gray-500"
            />
        </Button>
        ,
        <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={openSortMenu}
            onClose={handleCloseSortMenu}
            MenuListProps={{
                'aria-label': 'basic-button',
            }}
            sx={{
                zIndex: theme.zIndex.drawer + 2,
            }}
            className='transition-opacity duration-300 ease-in-out animate-fade-in'
        >
            <MenuItem
                key={0}
                onClick={handleSortAdsDescending}
                variant='body2'
                component='div'
                className='font-medium text-gray-900 block px-4 py-2 text-sm cursor-pointer'
            >
                Plus récents
            </MenuItem>
            <MenuItem
                key={1}
                onClick={handleSortAdsAscending}
                variant='body2'
                component='div'
                className='font-medium text-gray-900 block px-4 py-2 text-sm cursor-pointer'
            >
                Plus ancient
            </MenuItem>
        </Menu>
    ]
}

export default SortAds
