import React, { useEffect, useRef, useState } from 'react'
import { useCurrency } from '../../../utils/CurrencyProvider';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { CalendarToday, DateRange, Delete, Place, Scale, Sms, Visibility } from '@mui/icons-material';
import { statusColor, statusLabel } from '../../../data/adData';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { deleteAd } from '../../../services/adService';


const AdCard = ({ ad, setAds = [], setLoading = false, index }) => {

    const { currency } = useCurrency();
    const adIdRef = useRef();
    const [openDelete, setOpenDelete] = useState(false);
    const isMatch = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleClickDeleteAd = (id) => {
        adIdRef.current = id;
        setOpenDelete(true);
    };
    const [maxHeight, setMaxHeight] = useState(0);
    const cardRefs = useRef([]);
    useEffect(() => {
        // Calculate max height only once after the component mounts
        const calculateMaxHeight = () => {
            const heights = cardRefs.current.map((ref) => ref?.offsetHeight || 0);
            const maxCardHeight = Math.max(...heights);
            setMaxHeight(maxCardHeight); // Update state only if max height changes
        };

        calculateMaxHeight();
    }, []);

    return (
        <Card
            ref={(el) => (cardRefs.current[index] = el)}
            sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "space-between",
                height: !isMatch && maxHeight ? `${maxHeight}px` : "100%",
                width: isMatch ? "auto" : '100%',
                // m: 2,
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: 6,
                },
            }}
        >
            <div style={{ flex: 1 }}>
                <CardHeader
                    title={<Typography variant="h6">{ad.title}</Typography>}
                    subheader={
                        <div className='block md:flex md:gap-2 md:justify-between'>
                            <Typography
                                variant="body2"
                                sx={{ display: "block", fontSize: "0.8rem" }}
                            >
                                Crée le : {ad.creationDate && dayjs(ad.creationDate).format("DD-MM-YYYY HH:mm")}
                            </Typography>
                            {ad.publicationDate !== null &&
                                <Typography
                                    variant="body2"
                                    sx={{ display: "block", fontSize: "0.8rem" }}
                                >
                                    Publiée le : {ad.publicationDate && dayjs(ad.publicationDate).format("DD-MM-YYYY HH:mm")}
                                </Typography>
                            }
                        </div>
                    }
                    sx={{ pt: 0 }}
                />
                <CardContent sx={{ pr: 2, pt: 0 }}>
                    <Grid container spacing={1} alignItems="center">
                        {isMatch &&
                            <CardMedia
                                component="img"
                                image={ad.picture}
                                alt={ad.title}
                                sx={{
                                    height: "100px",
                                    width: "auto",
                                    minWidth: "100%",
                                    objectFit: 'cover',
                                    alignSelf: "stretch",
                                    borderRadius: 0,
                                    ml: 2,
                                }}
                            />
                        }
                        {/* Status */}
                        <Grid item xs={6}>
                            <Tooltip title={statusLabel(ad.status)}>
                                <Typography variant="subtitle2" display="flex" alignItems="center">
                                    <Chip
                                        label={statusLabel(ad.status)}
                                        color={statusColor(ad.status)}
                                        variant="outlined"
                                        size="small"
                                        sx={{ marginRight: 1, borderRadius: 1 }}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} display="flex" alignItems="center">
                            <Sms fontSize="small" sx={{ color: "var(--secondary)", marginRight: 1 }} />
                            <Typography variant="body2">{ad.offersNumber} réponses</Typography>
                        </Grid>
                        <Grid item xs={6} display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ color: "green", marginRight: 1, fontWeight: 'bold' }}>
                                {currency === 'MAD' ? 'MAD' : 'FCFA'}
                            </Typography>
                            <Typography variant="body2">
                                {currency === 'MAD' ? ad.price : ad.price * 60}
                            </Typography>
                        </Grid>

                        <Grid item xs={4} display="flex" alignItems="center">
                            <Scale fontSize="small" sx={{ color: "var(--secondary)", marginRight: 1 }} />
                            <Typography variant="body2">{ad.weight} kg</Typography>
                        </Grid>

                        {/* Min and Max Dates */}
                        <Grid item container xs={12} spacing={1} display="flex">
                            {ad && ad.type === "SALE" ? [
                                <Grid item xs={6} display="flex">
                                    <CalendarToday fontSize="small" sx={{ color: "orange", marginRight: 1 }} />
                                    <Typography variant="body2">
                                        Départ le {dayjs(ad.departureDate).format("DD-MM-YYYY")} &nbsp;
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        Arrivée le {dayjs(ad.arrivalDate).format("DD-MM-YYYY")} &nbsp;
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={12} display='flex'>
                                    <DateRange fontSize="small" sx={{ color: "orange", marginRight: 1 }} />
                                    <Typography variant="body2">
                                        Délai de dépôt le {dayjs(ad.depositDeadline).format("DD-MM-YYYY HH:MM")}
                                    </Typography>
                                </Grid>
                            ] : [
                                <Grid item xs={12} display='flex'>
                                    <CalendarToday fontSize="small" sx={{ color: "orange", marginRight: 1 }} />
                                    <Typography variant="body2">
                                        Entre le {dayjs(ad.minDate).format("DD-MM-YYYY")}
                                    </Typography>
                                    <Typography variant="body2">
                                        &nbsp;{"et le"} {dayjs(ad.maxDate).format("DD-MM-YYYY")}
                                    </Typography>
                                </Grid>
                            ]}
                        </Grid>

                        {/* Lieu */}
                        <Grid item xs={12} display='flex'>
                            {/* <Place fontSize="small" sx={{ color: "var(--primary)", marginRight: 1 }} /> */}
                            <Typography variant="body2" fontWeight='bold'>
                                {ad.departureRelay.city} - {ad.arrivalRelay.city}
                            </Typography>
                        </Grid>

                        {/* Offers */}
                        {/* <Grid item xs={6} display="flex" alignItems="center">
                            <Sms fontSize="small" sx={{ color: "var(--secondary)", marginRight: 1 }} />
                            <Typography variant="body2">{ad.offersNumber} réponses</Typography>
                        </Grid> */}
                    </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-start", pr: 2, pt: 0 }}>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<Visibility />}
                        onClick={() => { window.location.href = `/user/ads/${ad.id}` }}
                    >
                        Détails
                    </Button>
                    <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        startIcon={<Delete />}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClickDeleteAd(ad.id);
                        }}
                    >
                        Supprimer
                    </Button>
                </CardActions>
            </div>
            {!isMatch &&
                <CardMedia
                    component="img"
                    image={ad.picture}
                    alt={ad.title}
                    sx={{
                        width: "30%",
                        height: "auto",
                        maxHeight: "100%",
                        objectFit: 'cover',
                        alignSelf: "stretch",
                        // borderRadius: "0 8px 8px 0",
                    }}
                />
            }
            <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete} id={adIdRef.current} deleteMethod={deleteAd} setData={setAds} />
        </Card>
    )
}

export default AdCard