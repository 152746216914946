import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import { createUser } from '../../../services/userService'
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField'
import PasswordInput from '../../public_components/Layouts/PasswordInput'
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert'

const UserCreateDialog = ({ setLoading, open, setOpen, setUsers }) => {
  const { jwt } = useJwt();

  const initialState = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    username: "",
    password: "",
  }
  const [user, setUser] = useState(initialState);
  const [error, setError] = useState(initialState);

  const updateError = (prop, value) => {
    setError(prev => ({
      ...error,
      [prop]: value
    }));
  }

  const updateUser = (prop, value) => {
    setUser(prevUser => ({
      ...prevUser,
      [prop]: value
    }));
    if (!value) {
      updateError(prop, "Ce champ est obligatoire");
    }
    else {
      updateError(prop, '');
    }
  }


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(user?.phone)
    const savedUser = await createUser(user, jwt);
    if (savedUser !== null) {
      setOpen(false);
      showAlert("Utilisateur créé avec succès !", 'success')
      setUsers(prev => [savedUser, ...prev]);
    }
    setLoading(false);
  };

  const handleCloseCreate = () => {
    setOpen(false);
    setUser(initialState);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseCreate}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
      className='transition-opacity duration-300 ease-in-out animate-fade-in'
    >
      <DialogTitle>Créer un utilisateur</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            id="firstName"
            label="Prénom"
            onChange={(e) => updateUser("firstName", e.target.value)}
          />
          <TextField
            required
            fullWidth
            id="lastName"
            label="Nom"
            onChange={(e) => updateUser("lastName", e.target.value)}
            error={error.lastName !== ''}
            helpertext={error.lastName !== '' ? error.lastName : undefined}
          />
          <PhoneNumberField
            phone={user.phone}
            update={updateUser}
          />
          <TextField
            fullWidth
            id="email"
            label="Adresse email"
            onChange={(e) => updateUser("email", e.target.value)}
          />
          <TextField
            required
            fullWidth
            label="Nom d'utilisateur"
            id="username"
            onChange={(e) => updateUser("username", e.target.value)}
            error={error.username !== ''}
            helpertext={error.username !== '' ? error.username : undefined}
          />
          <PasswordInput name={"password"} password={user?.password} update={updateUser} error={error} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleCloseCreate}>Annuler</Button>
        <Button variant='contained' type="submit">Ajouter</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserCreateDialog