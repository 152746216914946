import ajax from "./ajax";

export const saveContent = async (content, jwt) => {
  return ajax("/user/contents/create", "POST", jwt, content);
};

export const getContents = async () => {
  return ajax("/api/contents", "GET");
};

export const getContentById = async (contentId, jwt) => {
  return ajax(`/user/contents/${contentId}`, "GET", jwt);
};
 
export const updateContent = async (id, request, jwt) => {
  const requestBody = {
    designation: request.designation,
  };
  return ajax(`/coordinator/contents/${id}`, "PUT", jwt, requestBody);
};

export const deleteContent = async (contentId, jwt) => {
  return ajax(`/coordinator/contents/${contentId}`, "DELETE", jwt);
};
