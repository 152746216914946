import React, { useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CssBaseline, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel,
    MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, TextField,
    Typography
} from '@mui/material';
import { saveAd } from '../../../services/adService';
import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getRelays } from '../../../services/relayService';
import { Cancel, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { getContents } from '../../../services/contentService';
import CreateContentDialog from '../ContentCrud/CreateContentDialog';
import { useJwt, useRole } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert';
import NavBar from '../../public_components/Layouts/NavBar';
import { DrawerHeader, MainDrawer } from '../../public_components/Layouts/Drawer';
import Footer from '../../public_components/Layouts/Footer';
import { useCurrency } from '../../../utils/CurrencyProvider';
import { calculPrice } from '../../../utils/Price';

const AdCreate = ({ setLoading, setAds }) => {

    const { jwt } = useJwt();
    const [relays, setRelays] = useState([]);

    const [open, setOpen] = useState(false);
    const { role } = useRole();
    const { currency } = useCurrency();

    const initialAd = {
        title: '',
        type_name: 'SALE',
        picture: '',
        weight: 0,
        price: 0,
        note: '',
        departureRelay: null,
        arrivalRelay: null,
        depositDeadline: dayjs('', "DD-MM-YYYY HH:mm"),
        departureDate: '',
        arrivalDate: '',
        stopoversDetails: '',
        luggageNumber: 0,
        luggageDescription: '',
        minDate: '',
        maxDate: '',
        adContentIds: [],
    }
    const [ad, setAd] = useState(initialAd);
    const [contentTypes, setContentTypes] = useState([]);
    const [openCreateContent, setOpenCreateContent] = useState(false);
    // const [emballage, setEmballage] = useState(false);
    const [error, setError] = useState({
        type_name: '',
        weight: '',
        price: '',
        departureRelay: '',
        arrivalRelay: '',
        depositDeadline: '',
        departureDate: '',
        arrivalDate: '',
        luggageNumber: '',
        luggageDescription: '',
        minDate: '',
        maxDate: '',
        adContentIds: '',
    });

    const updateError = (prop, value) => {
        setError(prev => ({
            ...prev,
            [prop]: value
        }));
    }

    const updateAd = (prop, value) => {
        setAd(prevAd => ({
            ...prevAd,
            [prop]: value
        }))
        if (!value) {
            updateError(prop, "Ce champ est obligatoire.")
        }
        else {
            updateError(prop, '');
        }
        calculPrice(ad, setAd, prop, value, updateError);
    }


    useEffect(() => {
        const fetchRelays = async () => {
            setLoading(true);
            const relaysData = await getRelays();
            if (relaysData !== null) {
                setRelays([...relaysData]);
            }
            setLoading(false);
        };

        fetchRelays();

    }, [setLoading]);

    useEffect(() => {
        const fetchContents = async () => {
            setLoading(true);
            const contentsData = await getContents();
            if (contentsData !== null) {
                setContentTypes([...contentsData]);
            }
            setLoading(false);
        };

        fetchContents();
    }, [openCreateContent, setLoading]);

    const handleOpenCreateContent = () => {
        setOpenCreateContent(true);
    };

    const handleCloseCreateAd = () => {
        setAd(initialAd);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formattedAd = {
            ...ad,
            adContentIds: ad.adContentIds?.map(content => content.id),
            // price: emballage ? ad.price + 80 * ad.luggageNumber : ad.price,
        };
        if (formattedAd.weight > 23 || formattedAd.weight < 1) {
            showAlert("Entrez un poids de bagage entre 1 et 23 kg !", 'error');
            setLoading(false);
            return;
        }
        const savedAd = await saveAd(formattedAd, jwt);
        if (savedAd !== null) {
            // setAds(prev => [savedAd, ...prev]);
            showAlert("Annonce créée avec succès et en attente de validation !", 'success');
            handleCloseCreateAd();
            window.location.href = (role === 'USER') ? '/user/ads/published' : '/ads/manage';
        }
        setLoading(false);
    };

    const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
    const targetRefs = useRef([]); // Array of refs for all the elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute("data-index"), 10);
                        setVisibleItems((prevVisibleItems) => [
                            ...prevVisibleItems,
                            index,
                        ]);
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );
        // Observe all refs
        targetRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
        return () => {
            // Unobserve all refs
            targetRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 3, my: 5, pt: 5 }}>
                    <DrawerHeader />
                    <Grid item container xs={12}>
                        <Grid item xs={12}
                            data-index={0}
                            ref={(el) => (targetRefs.current[0] = el)}
                            className={`transition-opacity ease-in-out transform ${visibleItems.includes(0)
                                ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                }`}
                        >
                            <Typography variant='h5' className="px-5" >
                                Créer une annonce
                            </Typography>
                            <nav aria-label="Breadcrumb">
                                <ol className="flex max-w-2xl space-x-2 pt-4 md:pt-2 px-5 mb-5 lg:max-w-7xl">
                                    <li key={1}>
                                        <div className="flex">
                                            <a href={role && role === 'USER' ? '/user/ads/published' : '/ads/manage'} className="mr-2 border-b">
                                                Gestion des Annonces
                                            </a>
                                            <span className='text-gray-400'>{`/`}</span>
                                        </div>
                                    </li>
                                    <li key={2}>
                                        <div className="flex">
                                            <span className="text-gray-500 hover:text-gray-600">
                                                Créer
                                            </span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </Grid>
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    borderRadius: 3,
                                    m: 2,
                                }}
                                data-index={1}
                                ref={(el) => (targetRefs.current[1] = el)}
                                className={`transition-opacity ease-in-out transform ${visibleItems.includes(1)
                                    ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                    }`}
                            >
                                <CardContent>
                                    <form id="login" noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={2} sx={{ padding: 2 }}>
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Type d'annonce*</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <FormControl component="fieldset" required>
                                                        <RadioGroup
                                                            row
                                                            aria-label="Type"
                                                            value={ad.type_name}
                                                            onChange={(e) => updateAd("type_name", e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                key={1}
                                                                label="Je vends mes kilos"
                                                                value="SALE"
                                                                control={<Radio checked={ad.type_name === 'SALE'} />}
                                                            />
                                                            <FormControlLabel
                                                                key={2}
                                                                label="J'achète les kilos"
                                                                value="REQUEST"
                                                                control={<Radio checked={ad.type_name === 'REQUEST'} />}
                                                            />
                                                        </RadioGroup>
                                                        {error.type_name && <FormHelperText error>{error.type_name}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Titre</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Titre de l'annonce"
                                                        value={ad.title}
                                                        onChange={(e) => updateAd("title", e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12} sx={{ mt: 2 }}>
                                                    <FormLabel component="legend">Poids (en KG)*</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <TextField
                                                        key="weight"
                                                        required
                                                        fullWidth
                                                        size="small"
                                                        id="weight"
                                                        name="weight"
                                                        type='number'
                                                        inputProps={{ min: 0, max: 23 }}
                                                        value={ad.weight}
                                                        onChange={(e) => updateAd("weight", e.target.value)}
                                                        error={error.weight !== ''}
                                                        helperText={error.weight !== '' && error.weight}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Prix*</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                                                    <TextField
                                                        disabled
                                                        required
                                                        fullWidth
                                                        name="price"
                                                        size="small"
                                                        id="price"
                                                        type='number'
                                                        inputProps={{ min: 0 }}
                                                        value={ad.price}
                                                        onChange={(e) => updateAd("price", e.target.value)}
                                                        error={error.price !== ''}
                                                        helperText={error.price !== '' ? error.price : ad.type_name === 'SALE' ? currency === 'MAD' ? `Vous recevrez ${ad.price - (ad.price * 15) / 100} MAD` : `Vous recevrez ${(ad.price - (ad.price * 15) / 100) * 60} Fcfa` : currency === 'MAD' ? `(MAD)` : `${ad.price * 60} Fcfa`}
                                                        FormHelperTextProps={{ style: { background: 'var(--primary)', color: 'white', fontSize: '100%' } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Lieu</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <FormControl key="departureRelay" fullWidth required size='small' sx={{ mt: 2 }}>
                                                        <InputLabel id="departure-relay-label">Lieu de départ</InputLabel>
                                                        <Select
                                                            required
                                                            labelId="departure-relay"
                                                            id="departureRelay"
                                                            label="Lieu de départ"
                                                            value={ad.departureRelay ? ad.departureRelay : ''}
                                                            onChange={(e) => updateAd("departureRelay", e.target.value)}
                                                        >
                                                            {[
                                                                <MenuItem key={0} value={null}>
                                                                    <span className='text-gray-500 text-sm'>Selectionnez un relai</span>
                                                                </MenuItem>
                                                            ]}
                                                            {relays?.map((relay) => (
                                                                ((!ad.arrivalRelay) ||
                                                                    (ad.arrivalRelay && relay.id !== ad.arrivalRelay.id)) &&
                                                                [
                                                                    <MenuItem key={relay.id} value={relay}>
                                                                        {relay.city}, {relay.country}
                                                                    </MenuItem>
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {error.departureRelay && <FormHelperText error>{error.departureRelay}</FormHelperText>}
                                                    </FormControl>
                                                    <FormControl key="arrivalRelay" fullWidth required size='small' sx={{ mt: 2 }}>
                                                        <InputLabel id="arrival-relay-label">Lieu d'arrivée</InputLabel>
                                                        <Select
                                                            required
                                                            labelId="arrival-relay"
                                                            id="arrivalRelay"
                                                            label="Lieu d'arrivée"
                                                            value={ad.arrivalRelay ? ad.arrivalRelay : ''}
                                                            onChange={(e) => updateAd("arrivalRelay", e.target.value)}
                                                        >
                                                            {[
                                                                <MenuItem key={0} value={null}>
                                                                    <span className='text-gray-500 text-sm'>Selectionnez un relai</span>
                                                                </MenuItem>
                                                            ]}
                                                            {relays?.map((relay) => (
                                                                ((!ad.departureRelay) ||
                                                                    (ad.departureRelay && relay.id !== ad.departureRelay.id)) &&
                                                                [
                                                                    <MenuItem key={relay.id} value={relay}>
                                                                        {relay.city}, {relay.country}
                                                                    </MenuItem>
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {error.arrivalRelay && <FormHelperText error>{error.arrivalRelay}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Bagage(s)</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    {ad.type_name === 'REQUEST' ? (
                                                        <>
                                                            {/* <FormLabel component="legend">Emballage à l'aéroport ?
                                                                (
                                                                {currency === 'MAD' ? '80 MAD de plus à payer' : `${80 * 60} FCFA de plus à payer`} par bagage)</FormLabel>
                                                            <FormControl component="fieldset" required>
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="Emballage a l'aeroport"
                                                                    value={emballage}
                                                                    onChange={(e) => {
                                                                        setEmballage(e.target.value);
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        key={1}
                                                                        label="Oui"
                                                                        value="true"
                                                                        control={<Radio />}
                                                                    />
                                                                    <FormControlLabel
                                                                        key={2}
                                                                        label="Non"
                                                                        value="false"
                                                                        control={<Radio />}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl> */}
                                                            <TextField
                                                                key="luggageNumber"
                                                                required
                                                                fullWidth
                                                                size="small"
                                                                id="luggageNumber"
                                                                name="luggageNumber"
                                                                type='number'
                                                                label="Nombre de bagages"
                                                                inputProps={{ min: 0 }}
                                                                value={ad.luggageNumber}
                                                                onChange={(e) => updateAd("luggageNumber", e.target.value)}
                                                                error={error.luggageNumber !== ''}
                                                                helperText={error.luggageNumber !== '' && error.luggageNumber}
                                                                sx={{ mt: 3 }}
                                                            />
                                                            <TextField
                                                                key="luggageDescription"
                                                                required
                                                                fullWidth
                                                                size="small"
                                                                id="luggageDescription"
                                                                name="luggageDescription"
                                                                label="Description des bagages"
                                                                value={ad.luggageDescription}
                                                                onChange={(e) => updateAd("luggageDescription", e.target.value)}
                                                                error={error.luggageDescription !== ''}
                                                                helperText={error.luggageDescription !== '' && error.luggageDescription}
                                                                sx={{ mt: 3 }}
                                                            />
                                                            <Button key="addContentBtn" variant='outlined' size='small' onClick={handleOpenCreateContent} sx={{ mt: 3 }}>
                                                                Ajouter un type de contenu
                                                            </Button>
                                                            <FormControl key='adContentIds' size='small' fullWidth required sx={{ mt: 1 }}>
                                                                <InputLabel>Contenu des bagages</InputLabel>
                                                                <Select
                                                                    multiple
                                                                    value={ad.adContentIds}
                                                                    onChange={(e) => { updateAd("adContentIds", e.target.value) }}
                                                                    input={<OutlinedInput label="Contenu des bagages" />}
                                                                    renderValue={(selected) => (
                                                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                                                            {selected?.map((value) => (
                                                                                <Chip
                                                                                    key={value.id}
                                                                                    label={value.designation}
                                                                                    onDelete={() =>
                                                                                        updateAd("adContentIds", ad.adContentIds.filter((item) => item.id !== value.id))
                                                                                    }
                                                                                    deleteIcon={
                                                                                        <Cancel
                                                                                            onMouseDown={(event) => event.stopPropagation()}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            ))}
                                                                        </Stack>
                                                                    )}
                                                                >
                                                                    {contentTypes?.map((content) => [
                                                                        <MenuItem
                                                                            key={content.id}
                                                                            value={content}
                                                                            sx={{ justifyContent: "space-between" }}
                                                                        >
                                                                            {content.designation}
                                                                            {ad.adContentIds.includes(content) ? <CheckBox color="info" /> : <CheckBoxOutlineBlank color="info" />}
                                                                        </MenuItem>
                                                                    ])}
                                                                </Select>
                                                                {error.adContentIds && <FormHelperText error>{error.adContentIds}</FormHelperText>}
                                                            </FormControl>
                                                        </>
                                                    )
                                                        :
                                                        (
                                                            <TextField
                                                                key="luggageNumber"
                                                                required
                                                                fullWidth
                                                                size="small"
                                                                id="luggageNumber"
                                                                name="luggageNumber"
                                                                type='number'
                                                                label="Nombre maximum de bagages"
                                                                inputProps={{ min: 0 }}
                                                                value={ad.luggageNumber}
                                                                onChange={(e) => updateAd("luggageNumber", e.target.value)}
                                                                error={error.luggageNumber !== ''}
                                                                helperText={error.luggageNumber !== '' && error.luggageNumber}
                                                                sx={{ mt: 3 }}
                                                            />
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Dates</FormLabel>
                                                </Grid>
                                                {ad.type_name === 'SALE' &&
                                                    <Grid item md={8} xs={12}>
                                                        <MobileDatePicker
                                                            key='departureDate'
                                                            required
                                                            id="departureDate"
                                                            name="departureDate"
                                                            label="Date de départ *"
                                                            format='DD-MM-YYYY'
                                                            disablePast
                                                            maxDate={ad.arrivalDate}
                                                            value={ad.departureDate}
                                                            onChange={(newValue) => { updateAd("departureDate", newValue); }}
                                                            error={error.departureDate !== ''}
                                                            helperText={error.departureDate !== '' && error.departureDate}
                                                            sx={{ width: '100%', mt: 2 }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                        />
                                                        <MobileDatePicker
                                                            key='arrivalDate'
                                                            required
                                                            id="arrivalDate"
                                                            name="arrivalDate"
                                                            format='DD-MM-YYYY'
                                                            label="Date d'arrivée *"
                                                            minDate={ad.departureDate}
                                                            value={ad.arrivalDate}
                                                            onChange={(newValue) => { updateAd("arrivalDate", newValue); }}
                                                            error={error.arrivalDate !== ''}
                                                            helperText={error.arrivalDate !== '' && error.arrivalDate}
                                                            sx={{ width: '100%', mt: 3 }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                        />
                                                        <MobileDateTimePicker
                                                            key='depositDeadline'
                                                            required
                                                            id="depositDeadline"
                                                            name="depositDeadline"
                                                            label="Délai de dépôt au lieu de dépôt *"
                                                            format='DD-MM-YYYY HH:mm'
                                                            ampm={false}
                                                            minTime={dayjs('00:00', 'HH:mm')}
                                                            maxTime={dayjs('23:59', 'HH:mm')}
                                                            minDate={dayjs()}
                                                            maxDate={ad.departureDate}
                                                            value={ad.depositDeadline}
                                                            onChange={(newValue) => { updateAd("depositDeadline", dayjs(newValue).format("DD-MM-YYYY HH:mm")); }}
                                                            error={error.depositDeadline !== ''}
                                                            helperText={error.depositDeadline !== '' && error.depositDeadline}
                                                            sx={{ width: '100%', mt: 3 }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                        />
                                                    </Grid>
                                                }
                                                {ad.type_name === 'REQUEST' &&
                                                    <Grid item md={8} xs={12}>
                                                        <MobileDatePicker
                                                            key='minDate'
                                                            required
                                                            id="minDate"
                                                            name="minDate"
                                                            label="Date minimum *"
                                                            format='DD-MM-YYYY'
                                                            disablePast
                                                            maxDate={ad.maxDate}
                                                            value={ad.minDate}
                                                            onChange={(newValue) => { updateAd("minDate", newValue); }}
                                                            error={error.minDate !== ''}
                                                            helperText={error.minDate !== '' && error.minDate}
                                                            sx={{ width: '100%', mt: 2 }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                        />
                                                        <MobileDatePicker
                                                            key='maxDate'
                                                            required
                                                            id="maxDate"
                                                            name="maxDate"
                                                            label="Date maximum *"
                                                            format='DD-MM-YYYY'
                                                            minDate={ad.minDate}
                                                            value={ad.maxDate}
                                                            onChange={(newValue) => { updateAd("maxDate", newValue); }}
                                                            error={error.maxDate !== ''}
                                                            helperText={error.maxDate !== '' && error.maxDate}
                                                            sx={{ width: '100%', mt: 3 }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item md={4} xs={12}>
                                                    <FormLabel component="legend">Notes</FormLabel>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <TextField
                                                        id="note"
                                                        fullWidth
                                                        name="note"
                                                        rows={3}
                                                        helperText="Mentionnez des conditions ou un message pour l'acheteur"
                                                        value={ad.note}
                                                        onChange={(e) => updateAd("note", e.target.value)}
                                                    />
                                                </Grid>
                                                {ad.type_name === 'SALE' &&
                                                    <>
                                                        <Grid item md={4} xs={12}>
                                                            <FormLabel component="legend">Escales</FormLabel>
                                                        </Grid>
                                                        <Grid item md={8} xs={12}>
                                                            <TextField
                                                                id="stopovers"
                                                                fullWidth
                                                                name="stopovers"
                                                                rows={3}
                                                                helperText="Nombre et lieu de(s) stop(s) (Ne sera affiché qu'au coordinateur)"
                                                                defaultValue={ad.stopovers}
                                                                onChange={(e) => updateAd("stopovers", e.target.value)}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                        <CardActions>
                                            <Button variant='outlined' onClick={handleCloseCreateAd}>Annuler</Button>
                                            <Button variant='contained' onClick={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}>
                                                Enregistrer l'annonce
                                            </Button>
                                        </CardActions>
                                    </form>
                                </CardContent>
                            </Card >
                        </Grid>
                    </Grid>
                </Box>
                <CreateContentDialog setLoading={setLoading} openCreateContent={openCreateContent} setOpenCreateContent={setOpenCreateContent} />
                {role !== 'ADMIN' && role !== 'COORDINATOR' && <Footer />}
            </Box>
        </Box>
    )
}

export default AdCreate
