import { React, Fragment, useState, useEffect, useRef, forwardRef } from 'react'
import AdCard from './AdCard'
import { Close, FilterAlt } from '@mui/icons-material'
import Footer from '../Layouts/Footer'
import { getPublishedAds, searchAds } from '../../../services/adService'
import {
  Box, Divider, Grid, IconButton, Typography, useMediaQuery, CssBaseline, Tooltip,
  Dialog,
  Slide
} from '@mui/material'
import { getRelays } from '../../../services/relayService'
import { useLocation, useNavigate } from 'react-router-dom'
import AdSearchForm from './AdSearchForm'
import { useRole } from '../../../utils/UserProvider'
import NavBar from '../Layouts/NavBar'
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer'
import SortAds from '../../../utils/SortAds'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Ads = ({ setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useRole();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [ads, setAds] = useState([]);
  const [relays, setRelays] = useState([]);
  const [adsSearched, setAdsSearched] = useState([]);
  const initialSearchValues = {
    type: '',
    departureRelay: null,
    arrivalRelay: null,
  }
  const [adSearchRequest, setAdSearchRequest] = useState(initialSearchValues);
  const updateAdSearch = (prop, value) => {
    setAdSearchRequest(prevAd => ({
      ...prevAd,
      [prop]: value
    }));
  }

  useEffect(() => {
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      setRelays(relaysData);
      setLoading(false);
    };
    fetchRelays();
    const fetchAds = async () => {
      setLoading(true);
      const adsData = await getPublishedAds();
      if (adsData !== null) {
        setAds(adsData.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)));
      }
      setLoading(false);
    };
    fetchAds();
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get('ads-data');

    if (data !== null) {
      if (data) {
        const rawData = localStorage.getItem('adsSearch');
        try {
          const data = JSON.parse(rawData);
          // console.log('search result', data);
          setAdsSearched(data);
        } catch (parseError) {
          console.error("Error parsing adsSearch from localStorage:", parseError);
        }
      }
      else {
        setAdsSearched([]);
      }
    } else {
      localStorage.removeItem('adsSearch');
    }
    setLoading(false);

  }, [location, navigate, setLoading]);

  useEffect(() => {
    const search = async () => {
      setLoading(true);
      const ads = await searchAds(adSearchRequest);
      if (ads !== null) {
        const adsPublished = [];
        ads.forEach(ad => {
          if (ad.status === 'PUBLISHED') {
            adsPublished.push(ad);
          }
        });
        localStorage.setItem('adsSearch', JSON.stringify(adsPublished));
        setAdsSearched(adsPublished);
      }
      setLoading(false);
    };
    if (adSearchRequest.type || adSearchRequest.departureRelay || adSearchRequest.arrivalRelay) {
      search();
    }
  }, [adSearchRequest, setLoading]);

  // const handleSubmitSearch = async () => {
  //   try {
  //     const ads = await searchAds(adSearchRequest);
  //     localStorage.setItem('adsSearch', JSON.stringify(ads));
  //     // window.location.href = `/ads?ads-data=${encodeURIComponent(ads)}`;
  //     setAdsSearched(ads);
  //     setMobileFiltersOpen(false);
  //   }
  //   catch (error) {
  //     console.error("Failed to save ad:", error);
  //   }
  // };



  const handleResetSearch = () => {
    setLoading(true);
    setAdSearchRequest(initialSearchValues);
    setMobileFiltersOpen(false);
    localStorage.removeItem('adsSearch')
    window.location.reload();
    setLoading(false);
  }

  const [open, setOpen] = useState(false);

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
      <Box sx={{ flexGrow: 1 }}>
        <Box component="main" sx={{ p: 3, mt: 3 }}>
          <DrawerHeader />
          {/* Mobile filter dialog */}
          <Dialog
            open={mobileFiltersOpen}
            onClose={() => setMobileFiltersOpen(false)}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={true}
            sx={{
              "& .MuiDialog-paper": {
                margin: 0,
                pt: 5,
                maxWidth: "300px",
                width: "100%",
                height: "100%",
                position: "fixed",
                right: 0,
                boxShadow: 24,
                borderRadius: 0,
                overflowY: 'auto',

              },
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 2,
                borderBottom: "1px solid #ddd",
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="h6" color="gray">
                Filtres
              </Typography>
              <IconButton
                edge="end"
                color="gray"
                onClick={() => setMobileFiltersOpen(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Box>

            {/* Contenu principal */}
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <AdSearchForm
                adSearchRequest={adSearchRequest}
                updateAdSearch={updateAdSearch}
                relays={relays}
                handleResetSearch={handleResetSearch}
              />
            </Box>
          </Dialog>

          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div
              data-index={0}
              ref={(el) => (targetRefs.current[0] = el)}
              className={`flex items-baseline justify-between border-b border-gray-200 pb-6 pt-5 
                mx-auto transition-opacity ease-in-out transform ${visibleItems.includes(0)
                  ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Typography variant='h5'>Annonces</Typography>

              <div className="flex items-center">
                <SortAds setLoading={setLoading} adsSearched={adsSearched} setAdsSearched={setAdsSearched} ads={ads} setAds={setAds} />
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <Tooltip title="Filtres">
                    <FilterAlt sx={{ h: 5, w: 5 }} />
                  </Tooltip>
                </button>
              </div>
            </div>
            <Grid container justifyContent='center' spacing={2} sx={{ py: 2 }}>
              {isLargeScreen && (
                <>
                  <Grid item xs={12} lg={2.1} >
                    <Box
                      sx={{
                        position: 'static',
                        top: 80,
                        maxHeight: 'auto', // Adjust height as needed
                        overflowY: "auto",
                        scrollbarWidth: 'none',
                        backgroundColor: 'white',
                      }}
                      data-index={1}
                      ref={(el) => (targetRefs.current[1] = el)}
                      className={`mx-auto transition-opacity ease-in-out transform 
                        ${visibleItems.includes(1) ? "animate-slide-in-left" : "opacity-0 translate-y-10"
                        }`}
                    >
                      {/* Sidebar content */}
                      <Typography variant="h6" color='GrayText' sx={{ pb: 2 }}>
                        Filtres
                      </Typography>
                      <div className='hidden lg:block'>
                        <AdSearchForm adSearchRequest={adSearchRequest} updateAdSearch={updateAdSearch}
                          relays={relays} handleResetSearch={handleResetSearch}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={0.5} >
                    <Divider
                      orientation='vertical'
                      data-index={2}
                      ref={(el) => (targetRefs.current[2] = el)}
                      className={`mx-auto transition-opacity ease-in-out transform 
                      ${visibleItems.includes(2) ? "animate-slide-in-left" : "opacity-0 translate-y-10"
                        }`}
                    />
                  </Grid>
                </>
              )}
              {(adsSearched && (Object.keys(adsSearched).length > 0)) ?
                (
                  <Grid item container spacing={2} xs={12} lg={9.4}
                    data-index={3}
                    ref={(el) => (targetRefs.current[3] = el)}
                    className={`mx-auto transition-opacity ease-in-out transform 
                      ${visibleItems.includes(3) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                      }`}
                  >
                    {adsSearched.map((item) =>
                      <Grid item xs={12} sm={6} md={4} key={item.id}
                      >
                        <AdCard ad={item} />
                      </Grid>
                    )}
                  </Grid>

                ) : (
                  (localStorage.getItem('adsSearch') === null &&
                    (ads && (Object.keys(ads).length > 0))) ?
                    (
                      <Grid item xs={12} lg={9.4} container spacing={2}
                        data-index={3}
                        ref={(el) => (targetRefs.current[3] = el)}
                        className={`mx-auto transition-opacity ease-in-out transform 
                        ${visibleItems.includes(3) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                          }`}
                      >
                        {ads.map((item) =>
                          <Grid item xs={12} sm={6} md={4} key={item.id}
                          >
                            <AdCard ad={item} />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} lg={9.4} container spacing={2}
                        data-index={3}
                        ref={(el) => (targetRefs.current[3] = el)}
                        className={`mx-auto transition-opacity ease-in-out transform 
                        ${visibleItems.includes(3) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                          }`}
                      >
                        <Grid item xs={12} textAlign='center'>
                          <Typography variant='body1' color='GrayText' fontSize='22px' fontWeight='normal' sx={{ my: 4 }}>
                            Aucune Annonce trouvée.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        {ads.map((item) =>
                          <Grid item xs={12} sm={6} md={4} key={item.id}
                          >
                            <AdCard ad={item} />
                          </Grid>
                        )}
                      </Grid>
                    )
                )
              }
            </Grid>
          </div >
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default Ads