import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField';
import PasswordInput from '../../public_components/Layouts/PasswordInput';
import { useLocalState } from '../../../utils/useLocalStorage';
import { baseUrl } from '../../../services/ajax';
import showAlert from '../../../utils/ShowAlert';
import { Close } from '@mui/icons-material';

const Authentication = ({ setLoading, openLogin, openRegister, handleLoginOpen, handleRegisterOpen, handleClose }) => {
    const [jwt, setJwt] = useLocalState('jwt', '');
    const theme = useTheme();

    //Login
    const initialLogin = {
        username: '',
        password: ''
    }
    const [credentials, setCredentials] = useState(initialLogin);
    const [errorLogin, setErrorLogin] = useState(initialLogin);
    const updateErrorLogin = (prop, value) => {
        setErrorLogin(prev => ({
            ...errorLogin,
            [prop]: value
        }));
    }
    const updateCredentials = (prop, value) => {
        setCredentials(prev => ({
            ...credentials,
            [prop]: value
        }));
        if (value === '') {
            updateErrorLogin(prop, "Ce champ est obligatoire")
        }
        else {
            updateErrorLogin(prop, '');
        }
    }
    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (errorLogin.password || errorLogin.username) {
            showAlert("Nom d'utilisateur, email ou mot de passe incorrect", 'error');
        }
        else {
            fetch(`${baseUrl}/api/auth/sign-in`, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(credentials)
            })
                .then((response) => {
                    if (response.status === 200) {
                        handleClose();
                        return Promise.all([response.json(), response.headers])
                    }
                    else {
                        const errorMessage = response.headers.get('Error-Message');
                        if (errorMessage.includes("inactif")) {
                            const userEmail = response.headers.get('Error-Message').substring(8);
                            window.location.href = `/activate-inactive?email=${userEmail}`;
                            return Promise.reject("Activez votre compte pour vous connecter !");
                        }
                        else {
                            return Promise.reject(errorMessage);
                        }
                    }
                })
                .then(([body, headers]) => {
                    setJwt(headers.get("Authorization"));
                    if (body && body.role !== 'USER')
                        window.location.href = "/dashboard";
                    else
                        window.location.href = "/user/profile";
                })
                .catch((message) => {
                    showAlert(message, 'error');
                });
        }
        setLoading(false);
    }

    //Register
    const initialRegister = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        username: "",
        password: "",
    }
    const [user, setUser] = useState(initialRegister);
    const [errorRegister, setErrorRegister] = useState(initialRegister);
    const updateError = (prop, value) => {
        setErrorRegister(prev => ({
            ...errorRegister,
            [prop]: value
        }));
    }
    const updateUser = (prop, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [prop]: value
        }));
        if (!value) {
            updateError(prop, "Ce champ est obligatoire");
        }
        else {
            updateError(prop, '');
        }
    }

    const handleSubmitRegister = (e) => {
        e.preventDefault();
        setLoading(true);
        const formattedUser = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: user?.phone,
            email: user?.email,
            username: user?.username,
            password: user?.password,
        };

        fetch(`${baseUrl}/api/auth/sign-up`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(formattedUser)
        })
            .then((response) => {
                if (response.status === 200) {
                    showAlert("Inscription réussie ! Veuillez vérifier votre email pour activer votre compte.", 'success');
                    handleClose();
                    handleLoginOpen();
                } else if (response.status === 400) {
                    const errorMessage = response.headers.get("Error-Message");
                    showAlert(errorMessage, 'error');
                } else if (response.status === 403) {
                    console.error(response.text());
                    // window.location.href = "/403";
                    showAlert("Accès refusé: Vous n'avez pas le droit d'accéder à cette ressource.", 'error');
                }
            })
            .catch((message) => {
                console.error("Error making AJAX request:", message);
            })

        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 200);

        return () => clearTimeout(timer);
    }, [setLoading]);

    return (
        <div>
            <Dialog
                open={openLogin}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmitLogin
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                <DialogTitle sx={{ fontSize: 28, textAlign: 'center' }}>
                    Connexion
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent sx={{ mx: 3 }}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="username"
                        label="Nom d'utilisateur ou Adresse email"
                        name="username"
                        onChange={(e) => updateCredentials('username', e.target.value)}
                        error={errorLogin.username !== ''}
                        helperText={errorLogin.username}
                        sx={{ mb: 2 }}
                    />
                    <PasswordInput
                        name={"password"}
                        password={credentials.password}
                        update={updateCredentials}
                        error={errorLogin}
                    />
                </DialogContent>
                <DialogActions sx={{ mx: 5, mb: 3, display: 'block' }}>
                    <Button
                        fullWidth
                        variant='contained'
                        type="submit"
                        sx={{ mb: 2, p: 1.5 }}
                    >
                        Se Connecter
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/forgot-password" variant="body2">
                                Mot de passe oublié?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link variant="body2" sx={{ cursor: 'pointer' }} onClick={handleRegisterOpen}>
                                Créer un compte
                            </Link>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openRegister}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmitRegister
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                <DialogTitle sx={{ fontSize: 28, textAlign: 'center' }}>
                    Inscription
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent sx={{ mx: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="firstName"
                                label="Prénom"
                                onChange={(e) => updateUser("firstName", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Nom"
                                onChange={(e) => updateUser("lastName", e.target.value)}
                                error={errorRegister.lastName !== ''}
                                helpertext={errorRegister.lastName !== '' ? errorRegister.lastName : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneNumberField
                                phone={user.phone}
                                update={updateUser}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Adresse email"
                                onChange={(e) => updateUser("email", e.target.value)}
                                error={errorRegister.email !== ''}
                                helpertext={errorRegister.email !== '' ? errorRegister.email : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Nom d'utilisateur"
                                id="username"
                                onChange={(e) => updateUser("username", e.target.value)}
                                error={errorRegister.username !== ''}
                                helpertext={errorRegister.username !== '' ? errorRegister.username : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PasswordInput name={"password"} password={user?.password} update={updateUser} error={errorRegister} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mx: 5, mb: 3, display: 'block' }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        S'inscrire
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link variant="body2">
                                <div onClick={handleLoginOpen} className='cursor-pointer'>Déjà inscrit? Se connecter</div>
                            </Link>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Authentication
