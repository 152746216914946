import {
  CssBaseline, Divider, Box,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Slide,
  Dialog,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Close, FilterAlt } from '@mui/icons-material';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Footer from '../../public_components/Layouts/Footer';
import { getOffersByBidder, searchUserOffers } from '../../../services/offerService';
import { status_array } from '../../../data/offerData';
import NavBar from '../../public_components/Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import { useUser } from '../../../utils/UserProvider';
import { useJwt } from '../../../utils/UserProvider';
import { getRelays } from '../../../services/relayService';
// import OfferUserSearchForm from './OfferUserSearchForm';
import OfferCard from './UserOfferCard';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MinimalSelect = ({ filterLabel, options, value, prop, onFilterChange }) => {

  const handleChange = (e) => {
    const eValue = e.target.value;
    onFilterChange(prop, eValue); // Send the selected value to the parent
  };

  return (
    <Box>
      <FormControl
        variant="standard"
        sx={{
          // minWidth: 120,
          "& .MuiSelect-root": {
            fontSize: "0.875rem", // Small text
            padding: "4px 8px",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none", // Remove border
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none", // Remove border on focus
          },
          "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // Remove border on hover
          },
        }}
      >
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          size="small"
          disableUnderline
        >
          <MenuItem value={(prop !== 'departureRelay' && prop !== 'arrivalRelay') ? '' : null} disabled>
            {filterLabel}
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};


const BidderOffers = ({ setLoading }) => {
  const { jwt } = useJwt();
  const { user } = useUser();

  const [offers, setOffers] = useState([]);
  const [open, setOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [relays, setRelays] = useState([]);
  const [offersSearched, setOffersSearched] = useState([]);
  const initialSearchValues = {
    type: '',
    offerStatus: '',
    departureRelay: null,
    arrivalRelay: null,
    bidder: null,
  }
  const [offerSearchRequest, setOfferSearchRequest] = useState(initialSearchValues);
  const updateOfferSearch = (prop, value) => {
    setOfferSearchRequest(prevAd => ({
      ...prevAd,
      [prop]: value
    }));
  }

  useEffect(() => {
    const fetchAds = async () => {
      setLoading(true);
      const offersData = await getOffersByBidder(jwt, setOffers);;
      setOffers(offersData);
      setLoading(false);
    };
    const fetchRelays = async () => {
      setLoading(true);
      const relaysData = await getRelays();
      setRelays(relaysData);
      setLoading(false);
    };
    fetchRelays();

    if (jwt) {
      fetchAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])


  useEffect(() => {
    updateOfferSearch('bidder', user)
    const search = async () => {
      setLoading(true);
      const offersSearched = await searchUserOffers(offerSearchRequest, jwt);
      if (offersSearched !== null) {
        const offersData = [];
        offersSearched.forEach(offer => offersData.push(offer));
        localStorage.setItem('offersSearch', JSON.stringify(offersData));
        setOffersSearched(offersData);
      }
      setLoading(false);
    };
    if (offerSearchRequest.type || offerSearchRequest.offerStatus || offerSearchRequest.departureRelay || offerSearchRequest.arrivalRelay) {
      search();
    }
    else {
      localStorage.removeItem('offersSearch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerSearchRequest, setLoading]);

  const handleResetSearch = () => {
    setLoading(true);

    const resetValues = { ...initialSearchValues }; // Copy initial state
    setOfferSearchRequest(resetValues);
    // Clear offers and local storage
    setOffersSearched([]); // Clear currently displayed offers
    localStorage.removeItem("offersSearch");
    // Optional: If needed, close mobile filters
    setMobileFiltersOpen(false);
    // Update individual filters for immediate UI reset
    updateOfferSearch("type", "");
    updateOfferSearch("status", "");
    updateOfferSearch("departureRelay", null);
    updateOfferSearch("arrivalRelay", null);

    setLoading(false);
  }

  const dialogRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target)) {
        setMobileFiltersOpen(false);
      }
    };

    if (mobileFiltersOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileFiltersOpen]);

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      {user && (user.role === 'ADMIN' || user.role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
      <Box sx={{ flexGrow: 1, width: '100%', minWidth: '315px' }}>
        <Box component="main" sx={{ p: 3, mt: 8 }}>
          <DrawerHeader />
          {/* Mobile filter dialog */}
          <Dialog
            open={mobileFiltersOpen}
            onClose={() => setMobileFiltersOpen(false)}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={true}
            sx={{
              "& .MuiDialog-paper": {
                margin: 0,
                pt: 5,
                maxWidth: "300px",
                width: "100%",
                height: "100%",
                position: "fixed",
                right: 0,
                boxShadow: 24,
                borderRadius: 0,
                overflowY: 'auto',

              },
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 2,
                borderBottom: "1px solid #ddd",
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="h6" color="gray">
                Filtres
              </Typography>
              <IconButton
                edge="end"
                color="gray"
                onClick={() => setMobileFiltersOpen(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Box> */}
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                height: "100%",
                overflowY: "auto",
              }}
            >
              {/* <OfferUserSearchForm
                offerSearchRequest={offerSearchRequest}
                updateOfferSearch={updateOfferSearch}
                relays={relays}
                handleResetSearch={handleResetSearch}
              /> */}
            </Box>
          </Dialog>

          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div
              data-index={0}
              ref={(el) => (targetRefs.current[0] = el)}
              className={`flex items-baseline justify-between border-b border-gray-200 pb-6 pt-5 
                mx-auto transition-opacity ease-in-out transform ${visibleItems.includes(0)
                  ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Typography variant='h5'>Gestion des Réponses</Typography>
              <Divider />
              {/* <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 md:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <Tooltip title="Filtres">
                  <FilterAlt sx={{ h: 5, w: 5 }} />
                </Tooltip>
              </button> */}
            </div>
            {/* <form
              onReset={(e) => {
                e.preventDefault();
                handleResetSearch();
              }}
              className='w-full my-3 items-center justify-end gap-3 flex-wrap hidden md:flex'
            >
              <MinimalSelect
                filterLabel={'Type'}
                options={[
                  { label: "Vente", value: "SALE" },
                  { label: "Demande", value: "REQUEST" },
                ]}
                prop='type'
                value={offerSearchRequest.type}
                onFilterChange={updateOfferSearch}
              />
              <MinimalSelect
                filterLabel={'Statut'}
                options={status_array}
                prop='status'
                value={offerSearchRequest.offerStatus}
                onFilterChange={updateOfferSearch}
              />
              <MinimalSelect
                filterLabel={'Départ'}
                options={relays?.map((relay) => (
                  { label: relay.city, value: relay }
                ))}
                prop='departureRelay'
                value={offerSearchRequest.departureRelay ? offerSearchRequest.departureRelay : null}
                onFilterChange={updateOfferSearch}
              />
              <MinimalSelect
                filterLabel={'Arrivée'}
                options={relays?.map((relay) => (
                  { label: relay.city, value: relay }
                ))}
                prop='arrivalRelay'
                value={offerSearchRequest.arrivalRelay ? offerSearchRequest.arrivalRelay : null}
                onFilterChange={updateOfferSearch}
              />
              <Button
                variant="outlined"
                color="primary"
                size='small'
                type='reset'
              >
                Effacer le filtre
              </Button>
            </form> */}

            <Grid container spacing={5} sx={{ py: 2 }}
              data-index={3}
              ref={(el) => (targetRefs.current[3] = el)}
              className={`mx-auto transition-opacity ease-in-out transform 
                  ${visibleItems.includes(3) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              {(offersSearched && (Object.keys(offersSearched).length > 0)) ?
                (offersSearched.map((item) =>
                  <Grid item sm={12} lg={6} key={item.id}
                  >
                    <OfferCard offer={item} setLoading={setLoading} setOffers={setOffers} index={item.id} />
                  </Grid>
                )
                ) : (
                  (localStorage.getItem('offersSearch') === null &&
                    (offers && (Object.keys(offers).length > 0))) ?
                    (offers.map((item) =>
                      <Grid item xs={12} lg={6} key={item.id}
                      >
                        <OfferCard offer={item} setLoading={setLoading} setOffers={setOffers} index={item.id} />
                      </Grid>
                    )
                    ) : (
                      <Grid item xs={12} textAlign='center'>
                        <Typography variant='body1' color='GrayText' fontSize='22px' fontWeight='normal' sx={{ my: 4 }}>
                          Aucune Réponse trouvée.
                        </Typography>
                      </Grid>
                    )
                )
              }
            </Grid>
          </div >
        </Box>
        {user && (user.role !== 'ADMIN' && user.role !== 'COORDINATOR') && <Footer />}
      </Box>
    </Box >
  );
}

export default BidderOffers