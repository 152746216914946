import ajax from "./ajax";

export const saveOpeningTime = async (openingTime, jwt) => {
  return ajax(`/coordinator/opening-times/create`, "POST", jwt, openingTime);
};

export const getOpeningTimes = async (jwt) => {
  return ajax(`/user/opening-times`, "GET", jwt);
};
 

export const getOpeningTimesByRelay = async (relayId, jwt) => {
  return ajax(`/user/opening-times/relay/${relayId}`, "GET", jwt);
};

export const getOpeningTimeById = async (opTimeId, jwt) => {
  return ajax(`/user/opening-times/${opTimeId}`, "GET", jwt);
};

export const updateOpeningTime = async (openingTime, jwt) => {
  return ajax(`/coordinator/opening-times/${openingTime.id}`, "PUT", jwt, openingTime);
};

export const deleteOpeningTime = async (openingTimeId, jwt) => {
  return ajax(`/coordinator/opening-times/${openingTimeId}`, "DELETE", jwt);
};
