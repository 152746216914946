import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getAdById, updateAdProperties } from '../../../services/adService'
import { getBiddersByAd, getOffersByAd, updateOfferStatus } from '../../../services/offerService'
import { statusLabel, status_array } from '../../../data/adData'
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert'

const AdEditDialog = ({ setLoading, adId, openUpdateAd, setOpenUpdateAd, setAds }) => {
    const { jwt } = useJwt();

    const [ad, setAd] = useState(null);
    const [bidders, setBidders] = useState([]);
    const adRef = useRef();

    const editableStatus = status_array.filter((item) => {
        return item.value !== 'PENDING_VALIDATION' && item.value !== 'RESPONDED';
    });

    const updateAdFields = (prop, value) => {
        setAd(prev => ({
            ...prev,
            [prop]: value
        }))
    }

    useEffect(() => {
        const fetchBidders = async () => {
            setLoading(true);
            const biddersData = await getBiddersByAd(adId, jwt);
            if (biddersData !== null)
                setBidders(biddersData);
            setLoading(false);
        };

        if (jwt && adId)
            fetchBidders();
    }, [adId, jwt, setLoading]);

    useEffect(() => {
        const fetchAd = async () => {
            setLoading(true);
            const adData = await getAdById(adId);
            if (adData !== null) {
                setAd({ ...adData });
                adRef.current = adData;
            }
            setLoading(false);
        };
        if (jwt && adId) {
            fetchAd();
        }

    }, [adId, adRef, jwt, setLoading])

    const handleCloseUpdateAd = () => {
        setOpenUpdateAd(false);
        setAd(adRef.current);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const offers = await getOffersByAd(ad.id);
        if (offers !== null) {
            if (ad.customer !== null && ad.status === 'PUBLISHED') {
                offers.forEach((offer) => {
                    if (offer.bidder.id === ad.customer.id) {
                        offer.offerStatus = 'ACCEPTED';
                    }
                    else {
                        offer.offerStatus = 'REFUSED';
                    }
                    updateOfferStatus(offer, jwt);
                })
                ad.status = 'RESPONDED';
            }
        }

        const request = {
            ...ad,
            status: ad.status ? ad.status : ad.status,
            customer: ad.customer ? ad.customer : null,
        };

        const savedAd = await updateAdProperties(ad.id, request, jwt);
        if (savedAd !== null) {
            if (setAds) {
                setAds((prev) => {
                    return prev.findIndex((item) => item.id === savedAd.id ? savedAd : ad);
                })
            }
            showAlert("Modification effectuée avec succès !", 'success')
            handleCloseUpdateAd();
            window.location.reload();
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openUpdateAd}
            onClose={handleCloseUpdateAd}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
            className='transition-opacity duration-300 ease-in-out animate-fade-in'
        >
            <DialogTitle>Editer l'annonce</DialogTitle>
            <DialogContent>
                {ad &&
                    [
                        <FormControl fullWidth sx={{ my: 2 }} >
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId='status-label'
                                label="Status"
                                displayEmpty
                                value={ad.status}
                                onChange={(e) => updateAdFields('status', e.target.value)}
                            >
                                {[
                                    <MenuItem key={0} value={ad.status}>
                                        {statusLabel(ad.status)}
                                    </MenuItem>
                                ]}
                                {editableStatus?.map((option) => (
                                    //     ((ad.status !== 'CLOSED' && ad.status !== 'PUBLISHED') 
                                    adRef.current.status && (
                                        (option.value !== ad.status) &&
                                        (
                                            (adRef.current.status === 'PENDING_VALIDATION' && (option.value === 'PUBLISHED' || option.value === 'CANCELLED'))
                                            || (adRef.current.status === 'RESPONDED' && option.value !== 'PUBLISHED')
                                            || (adRef.current.status === 'IN_DEPARTURE' && option.value !== 'PUBLISHED')
                                            || (adRef.current.status === 'ON_THE_WAY' && option.value !== 'IN_DEPARTURE' && option.value !== 'PUBLISHED' && option.value !== 'CANCELLED')
                                            || (adRef.current.status === 'IN_ARRIVAL' && option.value === 'CLOSED')
                                            || (ad.status === 'PUBLISHED' && (option.value === 'CANCELLED' || option.value === 'IN_DEPARTURE' || option.value === 'ON_THE_WAY' || option.value === 'IN_ARRIVAL' || option.value === 'CLOSED'))
                                        )
                                        &&
                                        [
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ]
                                    )
                                ))}
                            </Select>
                        </FormControl>
                        ,
                        ad.offersNumber > 0 &&
                        ad.status === 'PUBLISHED' &&
                        <FormControl fullWidth sx={{ my: 2 }} >
                            <InputLabel id="customer-label">Client</InputLabel>
                            <Select
                                labelId='customer-label'
                                label='Client'
                                value={ad.customer}
                                onChange={(e) => updateAdFields('customer', e.target.value)}
                            >
                                {bidders?.map((bidder) =>
                                    [
                                        <MenuItem key={bidder.id} value={bidder}>
                                            {bidder.username}
                                        </MenuItem>
                                    ]
                                )}
                            </Select>
                        </FormControl>
                    ]
                }
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseUpdateAd}>Annuler</Button>
                <Button variant='contained' onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AdEditDialog