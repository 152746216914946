import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, CardHeader, CssBaseline, Divider, Tooltip } from '@mui/material';
import { deleteRelay, getAllRelays, reactivateRelay } from '../../../services/relayService';
import { Delete, TouchApp, Visibility } from '@mui/icons-material';
import RelayDialog from './RelayDialog';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from "../../public_components/Layouts/NavBar";
import { useJwt } from '../../../utils/UserProvider';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';

const ManageRelays = ({ setLoading }) => {
    const { jwt } = useJwt();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(5);

    const [relays, setRelays] = useState([]);
    const relayIdRef = useRef();
    const [openDeleteRelay, setOpenDeleteRelay] = useState(false);

    useEffect(() => {
        const fetchRelays = async () => {
            setLoading(true);
            const relaysData = await getAllRelays(jwt);
            setRelays(relaysData);
            setLoading(false);
        };

        if (jwt) {
            fetchRelays();
        }

    }, [jwt, setLoading]);

    const [openCreateRelay, setOpenCreateRelay] = useState(false);
    const handleClickCreateRelay = () => {
        setOpenCreateRelay(true);
    };

    const handleClickDeleteRelay = (id) => {
        relayIdRef.current = id;
        setOpenDeleteRelay(true);
    };

    // const handleDeleteRelay = async (id) => {
    //     setLoading(true);
    //     await deleteRelay(id, jwt);
    //     const relay = await getRelayToDelete(id, jwt);
    //     if (relay !== null) {
    //         setRelays(prev => {
    //             const index = prev.findIndex((item) => item.id === relay.id);
    //             prev = prev.map((a, i) => {
    //                 if (i === index)
    //                     return relay;
    //                 else
    //                     return a;
    //             })
    //             return prev;
    //         })
    //     }
    //     else {
    //         setRelays(prev => prev.filter((item) => item.id !== id));
    //     }
    //     setLoading(false);
    // }

    const handleReactivate = async (id) => {
        setLoading(true);
        const relayData = await reactivateRelay(id, jwt);
        if (relayData !== null) {
            setRelays(prev => {
                const index = prev.findIndex((item) => item.id === relayData.id);
                prev = prev.map((user, i) => {
                    if (i === index)
                        return relayData;
                    else
                        return user;
                })
                return prev;
            })
        }
        setLoading(false);
    }

    const columns = useMemo(() => [
        {
            field: 'actions',
            headerName: "Actions",
            type: 'actions',
            width: 250,
            renderCell: (params) => [
                params.row.active ?
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button
                            variant="outlined"
                            startIcon={<Visibility />}
                            color='info'
                            size='small'
                            onClick={() => {
                                navigate(`/relays/${params.id}`);
                            }}
                        >
                            Détails
                            {/* {params.row.active} */}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<Delete />}
                            color='error'
                            size='small'
                            onClick={(e) => {
                                e.preventDefault();
                                handleClickDeleteRelay(params.id);
                            }}
                        >
                            Supprimer
                        </Button>
                    </Box>
                    :
                    <Tooltip title="Réactiver">
                        <GridActionsCellItem
                            label="Réactiver"
                            color='info'
                            icon={<TouchApp />}
                            onClick={(e) => {
                                e.preventDefault();
                                handleReactivate(params.id);
                            }}
                        />
                    </Tooltip>
            ],
        },
        {
            field: 'active',
            headerName: "Actif",
            renderCell: (params) => params.row.active ? <span>Oui</span> : <span>Non</span>
        },
        {
            field: 'country',
            headerName: "Pays",
        },
        {
            field: 'city',
            headerName: "Ville",
        },
        {
            field: 'phone',
            headerName: "Téléphone",
            width: 130,
        },
        {
            field: 'email',
            headerName: "Email",
            width: 200,
        },
        {
            field: 'address',
            headerName: "Adresse",
            width: 300,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
    const targetRefs = useRef([]); // Array of refs for all the elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute("data-index"), 10);
                        setVisibleItems((prevVisibleItems) => [
                            ...prevVisibleItems,
                            index,
                        ]);
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );
        // Observe all refs
        targetRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
        return () => {
            // Unobserve all refs
            targetRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8, width: '50%' }}>
                <DrawerHeader />
                <Card
                    sx={{
                        borderRadius: 3,
                        px: 2,
                        py: 2,
                        marginBottom: 2,
                        width: '100%',
                        minHeight: '400px',
                    }}
                >
                    <CardHeader title="Gestion des points de relai"
                        sx={{
                            marginBottom: 2,
                        }}
                        data-index={0}
                        ref={(el) => (targetRefs.current[0] = el)}
                        className={`transition-opacity ease-in-out transform 
                          ${visibleItems.includes(0) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                            }`}
                    />
                    <Divider />
                    <CardContent>
                        <Button variant='contained'
                            onClick={handleClickCreateRelay}
                            sx={{ marginBottom: 5, flex: 'end' }}
                            data-index={1}
                            ref={(el) => (targetRefs.current[1] = el)}
                            className={`transition-opacity ease-in-out transform 
                              ${visibleItems.includes(1) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                }`}
                        >
                            Creer un point de relay
                        </Button>
                        <div
                            data-index={2}
                            ref={(el) => (targetRefs.current[2] = el)}
                            className={`transition-opacity ease-in-out transform 
                            ${visibleItems.includes(2) ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                }`}>
                            <DataGrid
                                columns={columns}
                                autoHeight
                                rows={relays}
                                initialState={{
                                    ...relays.initialState,
                                    pagination: { paginationModel: { pageSize: pageSize } },
                                    filter: {
                                        filterModel: {
                                            items: [],
                                            quickFilterExcludeHiddenColumns: true,
                                        },
                                    },
                                }}
                                pageSize={pageSize}
                                pageSizeOptions={[5, 10, 25]}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: { disableToolbarButton: true },
                                        csvOptions: { disableToolbarButton: true },
                                    },
                                }}
                                sx={{
                                    border: 'none',
                                    width: '100%',
                                    mb: 5,
                                }}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Box>
            <RelayDialog setLoading={setLoading} openCreateRelay={openCreateRelay} setOpenCreateRelay={setOpenCreateRelay} setRelays={setRelays} />
            <DeleteDialog setLoading={setLoading} open={openDeleteRelay} setOpen={setOpenDeleteRelay} id={relayIdRef.current} deleteMethod={deleteRelay} setData={setRelays} />
        </Box>
    );
};

export default ManageRelays;
