import { Box, CssBaseline, Grid, Paper, Typography, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { MainDrawer, DrawerHeader } from "../../public_components/Layouts/Drawer";
import NavBar from "../../public_components/Layouts/NavBar";
import { status_array } from "../../../data/adData";
import { LocalOffer, People, Storefront, VolumeDown } from "@mui/icons-material";
import { getRelays } from "../../../services/relayService";
import { getUsers } from "../../../services/userService";
import { getOffers } from "../../../services/offerService";
import { getAds } from "../../../services/adService";
import { Bar, Pie, } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'
import { useJwt } from "../../../utils/UserProvider";
import { useRole } from "../../../utils/UserProvider";

const Dashboard = ({ setLoading }) => {
  const [open, setOpen] = useState(false);
  const { jwt } = useJwt();
  const { role } = useRole();
  const [info, setInfo] = useState({
    ads: [],
    offers: [],
    users: [],
    relays: [],
  })
  const updateInfo = (prop, value) => {
    setInfo(prev => ({
      ...prev,
      [prop]: value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const adsData = await getAds(jwt);
      if (adsData !== null)
        updateInfo('ads', adsData);
      const offersData = await getOffers(jwt);
      if (offersData !== null)
        updateInfo('offers', offersData);
      if (role === 'ADMIN') {
        const usersData = await getUsers(jwt);
        if (usersData !== null)
          updateInfo('users', usersData);
      }
      const relaysData = await getRelays();
      if (relaysData !== null)
        updateInfo('relays', relaysData);
      setLoading(false);
    };

    if (jwt)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, setLoading])

  const typeLength = (type) => {
    return info.ads.filter((ad) => ad.type === type).length
  }

  const statusLength = (status) => {
    return info.ads.filter((ad) => ad.status === status).length
  }

  const cardItem = (name, info, icon) => {
    return [
      <Paper elevation={3} style={{ padding: 20 }}>
        <Stack direction='row' spacing={10} display='flex' justifyContent='space-around' alignItems='center'>
          <Box>
            <Typography variant="h4">{info && info.length}</Typography>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
          </Box>
          {icon}
        </Stack>
      </Paper>
    ]
  }

  // const options = {
  //   maintainAspectRatio: false,
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false
  //       }
  //     },
  //     y: {
  //       display: false,
  //       grid: {
  //         display: false
  //       }
  //     }
  //   },
  //   plugins: {
  //     legend: {
  //       display: false
  //     },
  //     title: {
  //       display: true,
  //       text: "Avg interest by month (days)",
  //       padding: {
  //         bottom: 30
  //       },
  //       weight: "bold",
  //       color: "#00325c",
  //       font: {
  //         size: 13
  //       },
  //       align: "start"
  //     },
  //     datalabels: {
  //       display: true,
  //       color: "white"
  //     }
  //   }
  // };

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8, width: '100%' }}>
        <DrawerHeader />
        <Grid container spacing={3} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}
            data-index={0}
            ref={(el) => (targetRefs.current[0] = el)}
            className={`transition-opacity ease-in-out transform ${visibleItems.includes(0)
              ? "animate-slide-in-up" : "opacity-0 translate-y-10"
              }`}
          >
            {cardItem('Annonces', info.ads, <VolumeDown color="primary" sx={{ fontSize: 50 }} />)}
          </Grid>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}
            data-index={1}
            ref={(el) => (targetRefs.current[1] = el)}
            className={`transition-opacity ease-in-out transform 
              ${visibleItems.includes(1)
              ? "animate-slide-in-up" : "opacity-0 translate-y-10"
              }`}
          >
            {cardItem('Offres', info.offers, <LocalOffer color="primary" sx={{ fontSize: 50 }} />)}
          </Grid>
          <Grid item xs={12} sm={6} md={role === 'ADMIN' ? 3 : 4}
            data-index={2}
            ref={(el) => (targetRefs.current[2] = el)}
            className={`transition-opacity ease-in-out transform 
              ${visibleItems.includes(2)
              ? "animate-slide-in-up" : "opacity-0 translate-y-10"
              }`}
          >
            {cardItem('Relais', info.relays, <Storefront color="primary" sx={{ fontSize: 50 }} />)}
          </Grid>
          {role === 'ADMIN' &&
            <Grid item xs={12} sm={6} md={3}
              data-index={3}
              ref={(el) => (targetRefs.current[3] = el)}
              className={`transition-opacity ease-in-out transform 
                ${visibleItems.includes(3)
                ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              {cardItem('Utilisateurs', info.users, <People color="primary" sx={{ fontSize: 50 }} />)}
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ minHeight: 370, padding: 20 }}
              data-index={4}
              ref={(el) => (targetRefs.current[4] = el)}
              className={`transition-opacity ease-in-out transform ${visibleItems.includes(4)
                ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Typography variant="h6" gutterBottom>
                Annonces par Type
              </Typography>
              {/* <div style={{ textAlign: 'center' }}>
                <CircleChart percentage={30} color="#FF6384" />
              </div> */}
              <Box sx={{ maxHeight: 300 }}>
                <Pie data={{
                  labels: ['Vente', 'Demande'],

                  datasets: [
                    {
                      label: "Nombre d'annonces",
                      data: [typeLength('SALE'), typeLength('REQUEST')],
                      backgroundColor: ['#FF6384', '#36A2EB'],
                      hoverBackgroundColor: ['#FF6384', '#36A2EB'],
                    },
                  ],
                }}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ minHeight: 370, padding: 20 }}
              data-index={5}
              ref={(el) => (targetRefs.current[5] = el)}
              className={`transition-opacity ease-in-out transform ${visibleItems.includes(5)
                ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                }`}
            >
              <Typography variant="h6" gutterBottom>
                Annonces par Status
              </Typography>
              <Box sx={{ maxHeight: 300, width: '100%' }}>
                <Bar data={{
                  labels: status_array.map((status) => status.label),
                  datasets: [
                    {
                      label: "Nombre d'annonces",
                      data: status_array.map((status) => statusLength(status.value)), // Example percentages
                      backgroundColor: ['#adb5bd', '#0fb2cc', '#0b89d7', '#fd7e74', '#0af1cf', '#fd7e14', '#198754', '#dc3545'],
                      hoverBackgroundColor: ['#adb5bd', '#0fb2cc', '#0b89d7', '#fd7e74', '#0af1cf', '#fd7e14', '#198754', '#dc3545'],
                    },
                  ],
                }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
