import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField, Typography, Box, CssBaseline } from '@mui/material'
import { Email, LocationOn, Phone } from '@mui/icons-material'
import Footer from '../Layouts/Footer'
import NavBar from '../Layouts/NavBar'
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer';
import { useRole } from '../../../utils/UserProvider'
import { contactMessage } from '../../../services/notificationService'
import showAlert from '../../../utils/ShowAlert'

const ContactPage = ({ setLoading }) => {
  const [open, setOpen] = useState(false);
  const { role } = useRole();

  const settings = {
    email: 'sendnous@gmail.com',
    phone: '+212 700225518',
    location: 'Maarif Casablanca, MA 20330'
  }
  const initialState = {
    fullname: '',
    email: '',
    messageText: '',
  };
  const [message, setMessage] = useState(initialState);

  const updateMessage = (prop, value) => {
    setMessage(prev => ({
      ...message,
      [prop]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const request = {
      message: `Nom: ${message?.fullname} \nEmail: ${message?.email}\nMessage: ${message?.messageText}`
    }
    const data = await contactMessage(request);
    if (data !== null) {
      showAlert("Message envoyé !", 'success');
      setMessage(initialState);
    }

    // const encodedMessage = encodeURIComponent(request);
    // const yourWhatsAppNumber = "212669353437"; // Include country code
    // console.log(`https://wa.me/${yourWhatsAppNumber}?text=${encodedMessage}`)
    // window.open(`https://wa.me/${yourWhatsAppNumber}?text=${encodedMessage}`, '_blank');

    setLoading(false);
  };

  const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
  const targetRefs = useRef([]); // Array of refs for all the elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setVisibleItems((prevVisibleItems) => [
              ...prevVisibleItems,
              index,
            ]);
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
    // Observe all refs
    targetRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
    return () => {
      // Unobserve all refs
      targetRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
      <Box sx={{ flexGrow: 1 }}>
        <Box component="main" sx={{ p: 3, mt: 8 }}>
          <DrawerHeader />
          <div className="flex lg:h-screen ">
            <div className="">
              <div
                data-index={0}
                ref={(el) => (targetRefs.current[0] = el)}
                className={`justify-center flex-1 max-w-6xl pb-4 mx-auto md:px-6
                  transition-opacity ease-in-out transform ${visibleItems.includes(0)
                    ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                  }`}
              >
                <Typography variant='h6' color='primary'>
                  Contact
                </Typography>
                <Typography variant='h5'>
                  Nous sommes là pour vous aider
                </Typography>
              </div>
              <div className="flex flex-wrap">
                <div
                  data-index={1}
                  ref={(el) => (targetRefs.current[1] = el)}
                  className={`relative w-full px-4 mb-2 md:mb-10 md:w-1/2 lg:mb-0
                    transition-opacity ease-in-out transform ${visibleItems.includes(1)
                      ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                    }`}
                >
                  <p className="max-w-md mt-5">
                    Vous avez quelque chose à dire, une question, un problème ?
                    <br />
                    Remplissez le formulaire, envoyez un e-mail ou contactez nous par téléphone.
                  </p>

                  <div className="mt-5">
                    {settings?.location && (
                      <div className="flex items-center mt-2 space-x-2 text-dark-600cyan">
                        <LocationOn className="w-4 h-4" color='primary' />
                        <span>{settings.location}</span>
                      </div>
                    )}
                    {settings?.email && (
                      <div className="flex items-center mt-2 space-x-2 text-dark-600cyan">
                        <Email className="w-4 h-4" color='primary' />
                        <a href={`mailto:${settings.email}`}>
                          {settings.email}
                        </a>
                      </div>
                    )}
                    {settings?.phone && (
                      <div className="flex items-center mt-2 space-x-2 text-dark-600cyan">
                        <Phone className="w-4 h-4" color='primary' />
                        <a href={`tel:${settings.phone}`}>{settings.phone}</a>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  data-index={2}
                  ref={(el) => (targetRefs.current[2] = el)}
                  className={`relative w-full px-4 mt-3 mb-3 md:mb-10 md:w-1/2 lg:mb-0
                    transition-opacity ease-in-out transform ${visibleItems.includes(2)
                      ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                    }`}
                >
                  <div className="block max-w-md rounded-lg bg-white p-6 shadow-lg">
                    <form onSubmit={handleSubmit} >
                      <TextField
                        fullWidth
                        id="fullname"
                        label="Nom complet"
                        variant="outlined"
                        value={message.fullname}
                        onChange={(e) => updateMessage('fullname', e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={message.email}
                        onChange={(e) => updateMessage('email', e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        id="messageText"
                        label="Message"
                        multiline
                        minRows={4}
                        value={message.messageText}
                        onChange={(e) => updateMessage('messageText', e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Button
                        fullWidth
                        type="submit"
                        variant='contained'
                      >
                        Envoyer
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Footer />

      </Box>
    </Box>
  );
}

export default ContactPage