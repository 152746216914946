import React, { useRef, useState } from 'react'
import Authentication from './Authentication';

const OpenAuth = ({ setLoading }) => {
    const [openLogin, setOpenLogin] = useState(true);
    const [openRegister, setOpenRegister] = useState(false);
    const urlParams = new URLSearchParams(window.location.search)
    const prevFromURL = urlParams.get("url") || "/";
    const prevURLRef = useRef();
    prevURLRef.current = prevFromURL;

    const handleLoginOpen = () => {
        setOpenLogin(true);
        setOpenRegister(false);
    };

    const handleRegisterOpen = () => {
        setOpenRegister(true);
        setOpenLogin(false);
    };

    const handleClose = () => {
        setOpenLogin(false);
        setOpenRegister(false);
        window.location.href = prevURLRef.current;
    };

    return (
        <Authentication setLoading={setLoading} openLogin={openLogin} openRegister={openRegister} handleLoginOpen={handleLoginOpen}
            handleRegisterOpen={handleRegisterOpen} handleClose={handleClose} />
    )
};

export default OpenAuth
