import React from 'react'
import './AdCard.css'
import { useNavigate } from 'react-router-dom'
import { Scale, Sms } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useCurrency } from '../../../utils/CurrencyProvider';
import { Box, Typography } from '@mui/material';

const AdCard = ({ ad }) => {

    const navigate = useNavigate();
    const { currency } = useCurrency();

    return (
        <div onClick={() => navigate(`/ads/${ad.id}`)}
            className='adCard w-[15rem] h-[20rem] m-3 transition-all cursor-pointer flex flex-col items-center border rounded-md'>
            <div className="relative w-[15rem] h-[10rem]">
                <img src={ad.picture}
                    className='object-cover object-top w-full h-full' alt='Ad luggage' />
                <div className="bg-primary rounded text-white absolute start-0 top-0 m-4 py-1 px-3">
                    {ad.title}
                </div>
                {/* <div className="textPart bg-white absolute end-0 bottom-0 mx-2 px-1">
                    <span className='text-sm text-primary'>{ad.offersNumber}</span>
                    <Sms color='primary' sx={{ height: 20 }} />
                </div> */}
                <div className="textPart flex flex-row space-x-3 align-center bg-white rounded-t absolute start-0 bottom-0 mx-2 py-1 px-2">
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: "green", fontWeight: 'bold' }}>
                            {currency === 'MAD' ? 'MAD' : 'FCFA'}
                        </Typography>
                        <Typography variant="body2">
                            {currency === 'MAD' ? ad.price : ad.price * 60}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Scale fontSize="small" sx={{ color: "var(--secondary)" }} />
                        <Typography variant="body2">{ad.weight} kg</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Sms fontSize="small" sx={{ color: "var(--secondary)" }} />
                        <Typography variant="body2">{ad.offersNumber}</Typography>
                    </Box>
                </div>
            </div>
            <div className="w-[15rem] pr-6 pl-6 pb-2 pt-2 textPart bg-white">
                <p className='my-2 text-sm text-gray-500'>
                    <span>Publiée le: </span>
                    <span>{ad.publicationDate && dayjs(ad.publicationDate).format('DD-MM-YYYY HH:MM')}</span>
                </p>

                {/* <Typography color='GrayText' fontSize={13}>
                    Publiée le {ad.publicationDate && dayjs(ad.publicationDate).format('DD-MM-YYYY HH:MM')}
                </Typography> */}
                {ad && ad.type === 'SALE' && (
                    <>
                        <p className='mt-2 text-sm'>
                            <span>Délai de dépôt: </span>
                            <span className='font-semibold'>{ad.depositDeadline}</span>
                        </p>
                        <p className='mt-1 text-sm'>
                            <span>Départ le: </span>
                            <span className='font-semibold'>{ad.departureDate}</span>
                        </p>
                        <p className='mt-1 text-sm'>
                            <span>Arrivée le: </span>
                            <span className='font-semibold'>{ad.arrivalDate}</span>
                        </p>
                    </>
                )}
                {ad && ad.type === 'REQUEST' && (
                    <>
                        <p className='mt-2 text-sm'>
                            <span>Voyage entre le: </span>
                            <span className='font-semibold'>{ad.minDate}</span>
                        </p>
                        <p className='mt-1 text-sm'>
                            <span>Et le: </span>
                            <span className='font-semibold'>{ad.maxDate}</span>
                        </p>
                        <p className='mt-1 text-sm'>
                            <span>Nombre de bagage:  </span>
                            <span className='font-semibold'>{ad.luggageNumber}</span>
                        </p>
                    </>

                )}
                <p className='mt-2 text-sm font-semibold'>
                    {ad.departureRelay.city} - {ad.arrivalRelay.city}
                </p>
            </div>
        </div>
    )
}

export default AdCard