import React, { useEffect, useRef, useState } from 'react'
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, Select, MenuItem
} from '@mui/material';
import { updateAdProperties } from '../../../services/adService';
import { getOfferById, getOffersByAd, updateOfferStatus } from '../../../services/offerService';
import { statusLabel, status_array } from '../../../data/offerData';
import { useJwt } from '../../../utils/UserProvider';

const OfferEditDialog = ({ setLoading, offerId, openEdit, setOpenEdit, setOffers }) => {
    const { jwt } = useJwt();
    const [offer, setOffer] = useState();
    const offerRef = useRef();

    const updateOffer = (prop, value) => {
        setOffer(prev => ({
            ...prev,
            [prop]: value
        }))
    }

    useEffect(() => {
        const fetchOffer = async () => {
            setLoading(true);
            const responseData = await getOfferById(offerId, jwt);
            if (responseData !== null) {
                setOffer(responseData);
                offerRef.current = responseData;
            }
            setLoading(false);
        };
        if (jwt && offerId) {
            fetchOffer();
        }
    }, [jwt, offerId, setLoading]);

    const handleCloseEdit = () => {
        setOpenEdit(false);
        offerRef.current && setOffer(({ ...offerRef.current }));
    }
    const handleSubmitEdit = async () => {
        setLoading(true);
        const offers = await getOffersByAd(offer.ad.id);
        var offersStatus = true;
        if (offers !== null) {
            if (updateOffer.offerStatus === 'ACCEPTED' && offer.ad.status === 'PUBLISHED') {
                offersStatus = offers.every((o, index) => {
                    if (offer.bidder.id !== o.bidder.id)
                        o.offerStatus = 'REFUSED';
                    const responseData = updateOfferStatus(o, jwt);
                    if (responseData !== null) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                if (offersStatus)
                    updateAdProperties(offer.ad.id, { 'status': 'RESPONDED', 'customer': offer.bidder }, jwt);
            }
        }
        const responseData = updateOfferStatus(updateOffer, jwt);
        if (offersStatus && responseData !== null) {
            setOffer({ ...responseData });
            setOffers(prev => {
                const index = prev.findIndex((item) => item.id === responseData.id);
                prev = prev.map((user, i) => {
                    if (i === index)
                        return responseData;
                    else
                        return user;
                })
                return prev;
            })
            setOpenEdit(false);
        }
        setLoading(false);
    }

    return (

        <Dialog
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="edit-offer-status"
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmitEdit,
            }}
            className='transition-opacity duration-300 ease-in-out animate-fade-in'
        >
            <DialogTitle id={0}>
                Changer le status de la réponse
            </DialogTitle>
            <DialogContent>
                {offer &&
                    <FormControl fullWidth>
                        <FormLabel>Status</FormLabel>
                        <Select
                            labelId='status-label'
                            label="Status"
                            displayEmpty
                            value={offer.offerStatus}
                            onChange={(e) => updateOffer('offerStatus', e.target.value)}
                        >
                            {[
                                <MenuItem key={0} value={offer.offerStatus}>
                                    {statusLabel(offer.offerStatus)}
                                </MenuItem>
                            ]}
                            {status_array.map((option) => (
                                offer.offerStatus && (
                                    (option.value !== offer.offerStatus) &&
                                    (option.value !== 'PENDING_RESPONSE') &&
                                    (offer.offerStatus === 'PENDING_RESPONSE')
                                    &&
                                    [
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ]
                                )
                            ))}
                        </Select>
                    </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseEdit}>
                    Annuler
                </Button>
                <Button variant='contained' type='submit'>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OfferEditDialog
