import { LockOutlined } from '@mui/icons-material'
import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const ForbiddenPage = () => {
    return (
        <Container maxWidth="md">
            <Grid container spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh'
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                <Grid item xs={6}>
                    <Typography variant="h1">
                        403
                    </Typography>
                    <Typography variant="h4">
                        Access Interdit
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: 5 }}>
                        vous n'êtes pas autorisé à accéder à cette page.
                    </Typography>
                    <Button variant="contained" href='/'>
                        Retourner à la page d'accueil
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <LockOutlined sx={{ width: 400, height: 250 }} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ForbiddenPage