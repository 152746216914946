import React, { useEffect, useRef, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { Tab, Tabs, useMediaQuery, Button, AppBar as MuiAppBar, Toolbar, Box, Stack, IconButton, Avatar, Typography, Menu, MenuItem } from '@mui/material';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { PageDrawer } from './Drawer';
import MenuUser from './MenuUser';
import { useJwt, useUser } from '../../../utils/UserProvider';
import Authentication from '../../user_components/Auth/Authentication';
import Notifications from '../../user_components/Notifications/Notifications';
import { useLocalState } from '../../../utils/useLocalStorage';
import { KeyboardArrowDown } from '@mui/icons-material';


const AppBar = styled(MuiAppBar)(({ theme }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxHeight: 40,
    width: '100%',
    color: "white",
    backgroundColor: 'var(--primary)',
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 30,
    },
}));

const NavBar = ({ setLoading, open, setOpen }) => {
    const { jwt } = useJwt();
    const { user } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    // const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const isMatch = useMediaQuery('(max-width:935px)');
    const [currency, setCurrency] = useLocalState('currency', 'MAD');

    const userRef = useRef();
    userRef.current = user;

    let pages = [
        { name: 'Accueil', href: '/' },
        { name: 'Annonces', href: '/ads' },
        { name: 'A Propos', href: '/about' },
        { name: 'Contact', href: '/contact' },
    ]
    const routeToTabIndex = {
        "/": 0,
        "/ads": 1,
        "/about": 2,
        "/contact": 3,
    };

    const [openLogin, setOpenLogin] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const openUserMenu = Boolean(anchorEl);
    const handleUserClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserMenu = (event) => {
        setAnchorEl(null);
    };

    const handleLoginOpen = () => {
        setOpenLogin(true);
        setOpenRegister(false);
    };

    const handleRegisterOpen = () => {
        setOpenRegister(true);
        setOpenLogin(false);
    };

    const handleClose = () => {
        setOpenLogin(false);
        setOpenRegister(false);
    };

    // Helper function to convert the current path to a tab index
    const getCurrentTabValue = () => {
        if (location.pathname.match("/ads/") && location.pathname.match("/ads/").length > 0) {
            return 1;
        }
        return routeToTabIndex[location.pathname] || 0;
    };

    const [value, setValue] = useState(getCurrentTabValue());

    useEffect(() => {
        setLoading(true);
        // Update the tab value when the location changes
        setValue(getCurrentTabValue());
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, setLoading]); // Re-run this effect when the location changes

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navbarRef = useRef(null); // Reference to the AppBar
    const [isSticky, setIsSticky] = useState(false); // State to track if AppBar is sticky
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        if (navbarRef.current) {
            const { offsetTop } = navbarRef.current;
            setOffset(offsetTop);
            const handleScroll = () => {
                if (window.scrollY > offset) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            };
            window.addEventListener('scroll', handleScroll);
            // Cleanup the event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };

        }
    }, [offset]);

    return (
        <AppBar
            open={open}
            sx={{
                position: 'fixed',
                // position: 'absolute',
                backgroundColor: 'white',
                boxShadow: 'none',
                left: 0, // Move to the right on smaller screens
                right: 'auto',
                top: 0,
                width: '100%',
                // minWidth: '250px',
                minWidth: '315px',
            }}
        >
            <StyledToolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                </Typography>
                <form className="max-w-sm mx-auto ">
                    <select
                        id="small"
                        name='currency'
                        value={currency}
                        onChange={(e) => {
                            setCurrency(e.target.value);
                            window.location.reload();
                        }}
                        className="block w-full p-1 text-sm text-white cursor-pointer border border-cyan-300 rounded-md
                        bg-cyan-600 hover:bg-cyan-600/25 bg-opacity-50 border-cyan-500 focus:outline-none"
                    >
                        <option className='bg-white text-gray-600' value="MAD">MAD</option>
                        <option className='bg-white text-gray-600' value="FCFA">FCFA</option>
                    </select>
                </form>
            </StyledToolbar>
            <Toolbar
                ref={navbarRef}
                sx={{
                    position: isSticky ? 'fixed' : 'relative',
                    top: isSticky ? '0' : 'auto',
                    backgroundColor: isSticky ? 'white' : 'transparent',
                    borderBottom: isSticky && "1px solid rgba(0, 0, 0, 0.2)",
                    transition: '.4s',
                    color: "black",
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box key={0} display='flex' flexDirection='row' alignItems='center'>
                    <Link to="/" sx={{ marginLeft: "3px" }}>
                        <img
                            className={isMatch ? "h-10 w-10 mr-0" : "h-10 w-10 mr-2"}
                            src={process.env.PUBLIC_URL + "/logo512.png"}
                            alt="logo-SendNous"
                        />
                    </Link>
                    <Link to="/">
                        <img
                            className={isMatch ? "h-9 w-34 mr-2" : "h-10 w-full mr-2"}
                            src={process.env.PUBLIC_URL + "/logo_name.png"}
                            alt="logo-SendNous-name"
                        />
                    </Link>
                    {/* <Typography variant="h5" sx={{ color: 'var(--primary)' }} noWrap component="div">
                        SendNous
                    </Typography> */}
                </Box>
                {isMatch ?
                    [jwt ?
                        user ?
                            <MenuUser setLoading={setLoading} key={1} user={user} />
                            :
                            <Stack direction='row' spacing={1} sx={{ ml: 'auto', mr: 1, alignItems: 'center' }}>
                                <Notifications setLoading={setLoading} />
                                <IconButton>
                                    <Avatar
                                        sx={{
                                            bgcolor: 'var(--primary)',
                                            height: isMatch ? '28px' : '40px', width: isMatch ? '28px' : '40px',
                                        }}
                                    >
                                    </Avatar>
                                </IconButton>
                            </Stack>
                        :
                        <PageDrawer setLoading={setLoading} key={2} />
                    ]
                    :
                    [
                        <Tabs key={3} textColor="inherit" indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            sx={{ marginLeft: "auto" }}
                        >
                            {pages.map((page, index) =>
                            (
                                user && user.role === 'USER' && index === 1 ? (
                                    <>
                                        <Button
                                            onClick={handleUserClick}
                                            sx={{
                                                color: index === value ? 'black' : 'GrayText',
                                                borderRadius: 0,
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                        >
                                            Annonces
                                            <KeyboardArrowDown
                                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 
                                                text-gray-400 group-hover:text-gray-500"
                                            />
                                        </Button>
                                        <Menu
                                            id='basic-menu'
                                            anchorEl={anchorEl}
                                            open={openUserMenu}
                                            onClose={handleCloseUserMenu}
                                            MenuListProps={{
                                                'aria-label': 'basic-button',
                                            }}
                                            sx={{
                                                zIndex: theme.zIndex.drawer + 2,
                                            }}
                                            className='transition-opacity duration-300 ease-in-out animate-fade-in'
                                        >
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    navigate('/ads');
                                                    handleCloseUserMenu();
                                                }}
                                            >
                                                Annonces
                                            </MenuItem>
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    navigate('/user/ads/published');
                                                    handleCloseUserMenu();
                                                }}
                                            >
                                                Gérer Mes Annonces
                                            </MenuItem>
                                            <MenuItem
                                                key={2}
                                                onClick={() => {
                                                    navigate('/user/ads/responses');
                                                    handleCloseUserMenu();
                                                }}
                                            >
                                                Gérer Mes Réponses
                                            </MenuItem>
                                        </Menu>
                                    </>
                                ) : (
                                    <Tab key={index} label={page.name} LinkComponent="a" href={page.href} />
                                )
                            ))}
                        </Tabs>,
                        jwt ?
                            (
                                user ?
                                    <MenuUser setLoading={setLoading} key={4} user={user} />
                                    :
                                    <Stack direction='row' spacing={1} sx={{ ml: 'auto', mr: 1, alignItems: 'center' }}>
                                        <Notifications setLoading={setLoading} />
                                        <IconButton>
                                            <Avatar
                                                sx={{
                                                    bgcolor: 'var(--primary)',
                                                    height: isMatch ? '28px' : '40px', width: isMatch ? '28px' : '40px',
                                                }}
                                            >
                                            </Avatar>
                                        </IconButton>
                                    </Stack>
                            )
                            :
                            (<div key={5} className="ml-auto">
                                <Button variant="contained"
                                    onClick={handleLoginOpen}
                                >
                                    Se Connecter
                                </Button>
                                <Button variant="contained" sx={{ marginLeft: "10px" }}
                                    onClick={handleRegisterOpen}
                                >
                                    S'inscrire
                                </Button>
                            </div>
                            )
                    ]}

                {isMatch && jwt && <PageDrawer setLoading={setLoading} />}
            </Toolbar>
            <Authentication setLoading={setLoading} openLogin={openLogin} openRegister={openRegister} handleLoginOpen={handleLoginOpen}
                handleRegisterOpen={handleRegisterOpen} handleClose={handleClose} />
        </AppBar >
    )
}

export default NavBar