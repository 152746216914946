export function calculPrice(ad, setAd, prop, value, updateError) 
{
  if (prop === "weight") {
    if (value < 1 || value > 23) {
      updateError(prop, "Entrez un poids entre 1 et 23 kg.");
      return;
    }
    if (value <= 5) {
      setAd({ ...ad, price: value * 100, weight: value });
    } else if (value <= 10) {
      setAd({ ...ad, price: value * 90, weight: value });
    } else if (value <= 15) {
      setAd({ ...ad, price: value * 80, weight: value });
    } else if (value <= 20) {
      setAd({ ...ad, price: value * 70, weight: value });
    } else {
      setAd({ ...ad, price: 1400, weight: value });
    }
  }
};
