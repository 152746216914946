import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { saveRelay } from '../../../services/relayService'
import OpeningTimeDialog from '../OpeningTimeCrud/OpeningTimeCreateDialog'
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField'
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert'

const RelayDialog = ({ setLoading, openCreateRelay, setOpenCreateRelay, setRelays }
) => {
    const { jwt } = useJwt();

    const emptyRelay = {
        country: "",
        city: "",
        address: "",
        phone: "",
        email: "",
    }
    const [relay, setRelay] = useState(emptyRelay);
    const [error, setError] = useState(emptyRelay);
    // const [savedRelay, setSavedRelay] = useState(null);
    const [openingTime, setOpeningTime] = useState({
        openingHour: "",
        closingHour: "",
        dayOfWeek: "",
        relay: relay,
    })
    const updateError = (prop, value) => {
        setError(prev => ({
            ...prev,
            [prop]: value
        }));
    }
    const updateRelayFields = (prop, value) => {
        setRelay(prevRelay => ({
            ...prevRelay,
            [prop]: value
        }));
        if (!value)
            updateError(prop, 'Ce champ est obligatoire')
        else
            updateError(prop, '')

    }

    const [openAddOpTime, setOpenAddOpTime] = useState(false);

    const handleSubmitCreateRelay = async (e) => {
        setLoading(true);
        e.preventDefault();
        const savedRelay = await saveRelay(relay, jwt);
        if (savedRelay !== null) {
            setRelay({ ...savedRelay });
            setOpenCreateRelay(false);
            showAlert("Relai créé avec succès !", 'success')
            setOpenAddOpTime(true);
        }
        setLoading(false);
    };

    const handleCloseCreateRelay = () => {
        setOpenCreateRelay(false);
        setRelay(emptyRelay);
    };

    return (
        <>
            <Dialog
                open={openCreateRelay}
                onClose={handleCloseCreateRelay}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmitCreateRelay,
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                <DialogTitle>Créer un point de relai</DialogTitle>
                <DialogContent sx={{ marginRight: 5 }}>
                    <TextField
                        required
                        fullWidth
                        label="Pays"
                        name='country'
                        value={relay.country}
                        onChange={(e) => updateRelayFields("country", e.target.value)}
                        error={error.country !== ''}
                        helperText={error.country !== '' && error.country}
                        sx={{ marginY: 1 }}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Ville"
                        name='city'
                        value={relay.city}
                        onChange={(e) => updateRelayFields("city", e.target.value)}
                        error={error.city !== ''}
                        helperText={error.city !== '' && error.city}
                        sx={{ marginY: 1 }}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Adresse"
                        name='address'
                        value={relay.address}
                        onChange={(e) => updateRelayFields("address", e.target.value)}
                        error={error.address !== ''}
                        helperText={error.address !== '' && error.address}
                        sx={{ marginY: 1 }}
                    />
                    <div className='my-1'>
                        {/* <TextField
                            required
                            fullWidth
                            label="Téléphone"
                            name='phone'
                            value={relay.phone}
                            onChange={(e) => updateRelayFields("address", e.target.value)}
                            sx={{ marginY: 1 }}
                        /> */}
                        <PhoneNumberField
                            phone={relay.phone}
                            update={updateRelayFields}
                            updateError={updateError}
                        />
                    </div>
                    <TextField
                        required
                        fullWidth
                        label="Adresse email"
                        name='email'
                        type='email'
                        value={relay.email}
                        onChange={(e) => updateRelayFields("email", e.target.value)}
                        error={error.email !== ''}
                        helperText={error.email !== '' && error.email}
                        sx={{ marginY: 1 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseCreateRelay}>Annuler</Button>
                    <Button variant='contained' type="submit">Ajouter</Button>
                </DialogActions>
            </Dialog>
            <OpeningTimeDialog setLoading={setLoading} relay={relay} openingTime={openingTime} setOpeningTime={setOpeningTime}
                openAddOpTime={openAddOpTime} setOpenAddOpTime={setOpenAddOpTime} setRelays={setRelays}
            />
        </>
    )
}

export default RelayDialog