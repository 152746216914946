import React, { useEffect, useState } from 'react'
import ajax from '../services/ajax';
import { useJwt } from '../utils/UserProvider';

const PrivateRoute = ({ setLoading, children }) => {
  const { jwt } = useJwt();
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      if (!jwt) {
        setIsValid(false);
        setLoading(false);
        return;
      }

      try {
        const response = await ajax(`/api/auth/validate?token=${jwt}`, 'GET', jwt);
        setIsValid(response);
        setLoading(false);
      } 
      catch (error) {
        console.error('Error validating token:', error);
        localStorage.removeItem('jwt');
        setIsValid(false);
      }
      finally{
        setLoading(false);
      }
      setLoading(false);
    };

    validateToken();
  }, [jwt, setLoading]);

  if (isValid === false) {
    window.location.href = `/open-auth?url=${window.location.href}`;
  }

  return <>{children}</>;
};

export default PrivateRoute;