import React, { useEffect, useState } from 'react'
import { Notifications as MuiNotifications } from '@mui/icons-material';
import { getNotifications, readAllNotifications, readNotification } from '../../../services/notificationService';
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { useJwt } from '../../../utils/UserProvider';
import { Badge, IconButton, Button, Tooltip, Box, Menu, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { baseUrl } from '../../../services/ajax';

let stompClient = null;

export const connect = () => {
    const socket = new SockJS(`${baseUrl}/ws`);
    stompClient = Stomp.over(socket);
    stompClient.connect({}, () => {
        console.log("Connected to WebSocket");
    });
};

export const subscribeToNotifications = (user, callback) => {
    const trySubscribe = () => {
        if (stompClient && stompClient.connected) {
            stompClient.subscribe(`/topic/notifications/${user.id}`, (response) => {
                callback(JSON.parse(response.body));
            });
        } else {
            console.error("WebSocket connection is not open yet.");
            // setTimeout(trySubscribe, 1000); // Retry after 1 second
        }
    };

    trySubscribe();
};

const Notifications = ({ setLoading, user }) => {
    const { jwt } = useJwt();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        setLoading(true);
        // Establish WebSocket connection
        connect();

        return () => {
            if (stompClient && stompClient.connected) {
                stompClient.disconnect();
            }
            setLoading(false);
        };
    }, [setLoading]);

    useEffect(() => {
        const fetchNotificationsSocket = () => {
            setLoading(true);
            if (user !== null && user !== undefined) {
                subscribeToNotifications(user, notifications => {
                    setNotifications([...notifications].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
                })
                console.log(notifications);
            }
            setLoading(false);
        }

        const fetchNotifications = async () => {
            setLoading(true);
            if (user !== null && user !== undefined) {
                const notifs = await getNotifications(user.id, jwt);
                if (notifs !== null) {
                    setNotifications([...notifs].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
                }
            }
            setLoading(false);
        }
        if (stompClient && stompClient.connected) {
            fetchNotificationsSocket();
        }
        else {
            fetchNotifications();
        }
        return () => { };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, setLoading]);

    const handleReadNotif = async (id) => {
        setLoading(true);
        if (jwt && id) {
            const data = await readNotification(id, jwt);
            if (data !== null) {
                setNotifications(prev => (
                    prev.filter(notification => notification.id !== id)
                ));
            }
        }
        setLoading(false);
    };

    const handleReadAllNotifs = async () => {
        setLoading(true);
        if (jwt && user) {
            await readAllNotifications(user.id, jwt);
            setNotifications([]);
        }
        setLoading(false);
    };

    const [anchorElNotif, setAnchorElNotif] = useState(null);
    const openNotif = Boolean(anchorElNotif);

    const handleNotifClick = (event) => {
        setAnchorElNotif(event.currentTarget);
    };

    const handleCloseNotif = () => {
        setAnchorElNotif(null);
    };


    return (
        <div>
            <IconButton onClick={handleNotifClick} id='notif-menu'>
                <Badge
                    badgeContent={notifications.length}
                    color="error"
                    sx={{
                        backgroundColor: 'var(--primary)',
                        borderRadius: '50%',
                        p: 1,
                    }}
                >
                    <MuiNotifications
                        sx={{
                            color: 'white',
                            height: isMatch ? '20px' : '30px',
                            width: isMatch ? '20px' : '30px'
                        }} />
                </Badge>
            </IconButton>
            <Menu
                id='notif-menu'
                anchorEl={anchorElNotif}
                open={openNotif}
                onClose={handleCloseNotif}
                MenuListProps={{
                    style: {
                        minWidth: '300px',
                    }
                }}
                sx={{
                    zIndex: theme.zIndex.drawer + 2,
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                {notifications &&
                    [
                        notifications.length === 0 &&
                        <MenuItem key={0} onClick={handleCloseNotif}>
                            <Box component="div" whiteSpace="normal">
                                Aucune notification
                            </Box>
                        </MenuItem>
                        ,
                        notifications.map((notification => (
                            <MenuItem key={notification.id + 1} onClick={(e) => {
                                e.preventDefault();
                                handleReadNotif(notification.id);
                                handleCloseNotif();
                            }}>
                                <Tooltip title="Marquer lu">
                                    <Box component="div" whiteSpace="normal">
                                        {notification.message}
                                    </Box>
                                </Tooltip>
                            </MenuItem>
                        )))
                        ,
                        notifications.length > 0 &&
                        <MenuItem key={1} onClick={(e) => {
                            e.preventDefault();
                            handleReadAllNotifs();
                            handleCloseNotif();
                        }}>
                            <Button variant="text" color="primary" fullWidth>
                                Marquer tous lus
                            </Button>
                        </MenuItem>
                    ]
                }
            </Menu>

        </div>
    )
}

export default Notifications
