import React, { useEffect, useRef, useState } from 'react'
import { useCurrency } from '../../../utils/CurrencyProvider';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { statusColor, statusLabel } from '../../../data/offerData';
import { Delete, Luggage, Scale, Visibility } from '@mui/icons-material';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { deleteOffer } from '../../../services/offerService';

const OfferCard = ({ offer, setOffers = [], setLoading = false, index }) => {
    const { currency } = useCurrency();
    const offerIdRef = useRef();
    const [openDelete, setOpenDelete] = useState(false);
    const isMatch = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleClickDeleteOffer = (id) => {
        offerIdRef.current = id;
        setOpenDelete(true);
    };
    const [maxHeight, setMaxHeight] = useState(0);
    const cardRefs = useRef([]);
    useEffect(() => {
        // Calculate max height only once after the component mounts
        const calculateMaxHeight = () => {
            const heights = cardRefs.current.map((ref) => ref?.offsetHeight || 0);
            const maxCardHeight = Math.max(...heights);
            setMaxHeight(maxCardHeight); // Update state only if max height changes
        };

        calculateMaxHeight();
    }, []);

    return (
        <Card
            ref={(el) => (cardRefs.current[index] = el)}
            sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "space-between",
                height: !isMatch && maxHeight ? `${maxHeight}px` : "100%",
                width: isMatch ? "auto" : '100%',
                // m: 2,
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: 6,
                },
            }}
        >
            <div style={{ flex: 1 }}>
                <CardHeader
                    title={
                        < Typography
                            variant="h6"
                        >
                            Réponse à : {offer.ad.title}
                        </Typography>
                    }
                    sx={{ pt: 0 }}
                />
                <CardContent sx={{ pr: 2, pt: 0 }}>
                    <Grid container spacing={1} alignItems="center">
                        {isMatch &&
                            <CardMedia
                                component="img"
                                image={offer.ad.picture}
                                alt={offer.ad.title}
                                sx={{
                                    height: "100px",
                                    width: "auto",
                                    minWidth: "100%",
                                    objectFit: 'cover',
                                    alignSelf: "stretch",
                                    borderRadius: 0,
                                    ml: 2,
                                }}
                            />
                        }
                        {/* Status */}
                        <Grid item xs={6} sm={4}>
                            <Tooltip title={statusLabel(offer.offerStatus)}>
                                <Typography variant="subtitle2" display="flex" alignItems="center">
                                    <Chip
                                        label={statusLabel(offer.offerStatus)}
                                        color={statusColor(offer.offerStatus)}
                                        variant="outlined"
                                        size="small"
                                        sx={{ marginRight: 1, borderRadius: 1 }}
                                    />
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} sm={4} display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ color: "green", marginRight: 1, fontWeight: 'bold' }}>
                                {currency === 'MAD' ? 'MAD' : 'FCFA'}
                            </Typography>
                            <Typography variant="body2">
                                {currency === 'MAD' ? offer.price : offer.price * 60}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} display="flex" alignItems="center">
                            <Scale fontSize="small" sx={{ color: "var(--secondary)", marginRight: 1 }} />
                            <Typography variant="body2">{offer.ad.weight} kg</Typography>
                        </Grid>

                        {/* Offer */}
                        <Grid item container xs={12} spacing={1} display="flex" alignItems="center">
                            {offer && offer.ad.type === "SALE" ? [
                                <Grid item xs={1}>
                                    <Typography variant='body2' component='span'>
                                        <Luggage sx={{ height: 20 }} color='primary' />
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={3}>
                                    <Typography variant='body2' component='span'>
                                        {offer.luggageNumber} bagage(s)
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={8}>
                                    <Typography variant='body2' component='span'>
                                        {offer.luggageDescription}
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={12} display="flex" alignItems='end'>
                                    <Typography variant='body2' component='span' sx={{ color: 'GrayText', fontWeight: 'bold', fontSize: "0.8rem" }}>
                                        Contenu &nbsp;
                                    </Typography>
                                    {offer.offerContents && offer.offerContents.map((content) =>
                                        <Typography key={content.id} variant='body2' component='span'>
                                            {content.designation}
                                            {offer.offerContents[offer.offerContents.length - 1].id !== content.id &&
                                                <>&nbsp; . &nbsp;</>
                                            }
                                        </Typography>
                                    )}
                                </Grid>
                            ] : [
                                <Grid item xs={6} display="flex" alignItems='end'>
                                    <Typography variant='body2' component='span' sx={{ color: 'GrayText', fontWeight: 'bold', fontSize: "0.8rem" }}>
                                        Départ &nbsp;
                                    </Typography>
                                    <Typography variant='body2' component='span'>
                                        {offer.departureDate}
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={6} display="flex" alignItems='end'>
                                    <Typography variant='body2' component='span' sx={{ color: 'GrayText', fontWeight: 'bold', fontSize: "0.8rem" }}>
                                        Arrivée &nbsp;
                                    </Typography>
                                    <Typography variant='body2' component='span'>
                                        {offer.arrivalDate}
                                    </Typography>
                                </Grid>
                                ,
                                <Grid item xs={12} display="flex" alignItems='end'>
                                    <Typography variant='body2' component='span' sx={{ color: 'GrayText', fontWeight: 'bold', fontSize: "0.8rem" }}>
                                        Délai de dépot &nbsp;
                                    </Typography>
                                    <Typography variant='body2' component='span'>
                                        {offer.depositDeadline}
                                    </Typography>
                                </Grid>
                            ]}
                        </Grid>
                        <Grid item xs={12} display='flex'>
                            <Typography variant="body2" fontWeight='bold'>
                                {offer.ad.departureRelay.city} - {offer.ad.arrivalRelay.city}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-start", pr: 2, pt: 0 }}>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<Visibility />}
                        onClick={() => { window.location.href = `/ads/${offer.ad.id}` }}
                    >
                        Détails Annonce
                    </Button>
                    <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        startIcon={<Delete />}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClickDeleteOffer(offer.id);
                        }}
                    >
                        Supprimer
                    </Button>
                </CardActions>
            </div>
            {!isMatch &&
                <CardMedia
                    component="img"
                    image={offer.ad.picture}
                    alt={offer.ad.title}
                    sx={{
                        width: "30%",
                        height: "auto",
                        maxHeight: "100%",
                        objectFit: 'cover',
                        alignSelf: "stretch",
                        // borderRadius: "0 8px 8px 0",
                    }}
                />
            }
            <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete} id={offerIdRef.current} deleteMethod={deleteOffer} setData={setOffers} />
        </Card >
    )
}

export default OfferCard
