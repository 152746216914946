import React, { useEffect, useRef, useState } from 'react'
import {
    Button, CssBaseline, Card, CardActions, CardContent, CardHeader, CardMedia,
    Box, Divider, Grid, Typography
} from '@mui/material';
import Footer from '../Layouts/Footer';
import { CalendarToday, Done, Scale, Sms } from '@mui/icons-material';
import { getAdById } from '../../../services/adService';
import OfferCreateDialog from '../../user_components/Offers/OfferCreateDialog';
import { getOffersByAd } from '../../../services/offerService';
import NavBar from '../Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer';
import { useRole, useUser } from '../../../utils/UserProvider';
import dayjs from 'dayjs';
import { useJwt } from '../../../utils/UserProvider';
import { useCurrency } from '../../../utils/CurrencyProvider';


const AdDetails = ({ setLoading }) => {
    const { jwt } = useJwt();
    const { currency } = useCurrency();

    const adId = window.location.href.split("/ads/")[1];
    const [open, setOpen] = useState(false);
    const { user } = useUser();
    const { role } = useRole();

    const [responded, setResponded] = useState(false);
    const [ad, setAd] = useState({
        title: '',
        type: '',
        picture: '',
        weight: 23,
        price: 1100,
        depositDeadline: '',
        departureDate: '',
        arrivalDate: '',
        note: '',
        stopoversDetails: '',
        departureRelay: null,
        arrivalRelay: null,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const handleClickAnswerButton = () => {
        if (!jwt) {
            window.location.href = `/open-auth?url=${window.location.href}`;
        }
        else {
            setOpenDialog(true);
        }
    };

    useEffect(() => {
        const fetchAd = async () => {
            setLoading(true);
            const adData = await getAdById(adId);
            if (adData !== null) {
                setAd({ ...adData });
            }
            setLoading(false);
        };
        fetchAd();

    }, [adId, setLoading]);

    useEffect(() => {
        const fetchAdOffers = async () => {
            setLoading(true);
            const responseData = await getOffersByAd(adId);
            if (responseData !== null && user !== null) {
                if (responseData.find((offer) => offer.bidder?.id === user.id)) {
                    setResponded(true);
                }
            }
            setLoading(false);
        };

        fetchAdOffers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDialog, user, setLoading]);

    const [visibleItems, setVisibleItems] = useState([]); // Tracks which items are visible
    const targetRefs = useRef([]); // Array of refs for all the elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = parseInt(entry.target.getAttribute("data-index"), 10);
                        setVisibleItems((prevVisibleItems) => [
                            ...prevVisibleItems,
                            index,
                        ]);
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );
        // Observe all refs
        targetRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
        return () => {
            // Unobserve all refs
            targetRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 3 }}>
                    <DrawerHeader />
                    <div className="my-5 pt-5">
                        <Grid item container xs={12}>
                            <Grid item xs={12}
                                data-index={0}
                                ref={(el) => (targetRefs.current[0] = el)}
                                className={`transition-opacity ease-in-out transform ${visibleItems.includes(0)
                                    ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                    }`}
                            >
                                <Typography variant='h5' className="px-5" >
                                    Détails de l'annonce
                                </Typography>
                                <nav aria-label="Breadcrumb">
                                    <ol className="flex max-w-2xl space-x-2 pt-4 md:pt-2 px-5 lg:max-w-7xl">
                                        <li key={1}>
                                            <div className="flex">
                                                <a href='/ads' className="mr-2 border-b">
                                                    Annonces
                                                </a>
                                                <span className='text-gray-400'>{`>`}</span>
                                            </div>
                                        </li>
                                        <li key={2}>
                                            <div className="flex">
                                                <span className="text-gray-500 hover:text-gray-600">
                                                    {ad.title}
                                                </span>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </Grid>
                            <Grid item xs={12} md={3.5} order={{ xs: 2, md: 3 }}>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        m: 2,
                                    }}
                                    data-index={1}
                                    ref={(el) => (targetRefs.current[1] = el)}
                                    className={`transition-opacity ease-in-out transform ${visibleItems.includes(1)
                                        ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                        }`}
                                >
                                    {ad.picture && (
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={ad.picture}
                                            title={ad.title}
                                        />
                                    )}
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }} >
                                        <div className='mx-3'>
                                            <CalendarToday color='primary' sx={{ height: 20 }} />
                                            <span className='text-sm'>Publiée le {ad.publicationDate && dayjs(ad.publicationDate).format('DD-MM-YYYY HH:MM')}</span>
                                        </div>
                                        <div className='mx-3 flex'>
                                            <Sms fontSize="small" sx={{ color: "var(--primary)", marginRight: 1 }} />
                                            <Typography variant="body2">{ad.offersNumber} réponse(s)</Typography>
                                        </div>
                                    </CardContent>
                                    {/* {user && ad.publisher && user.id !== ad.publisher.id && */}
                                    <CardActions sx={{ justifyContent: 'flex-start', mb: 4, mx: 3 }}>
                                        {!responded ? (
                                            <Button
                                                // size='small'
                                                variant='contained'
                                                onClick={handleClickAnswerButton}
                                                sx={{ fontWeight: 'bold' }}
                                            >
                                                Répondre à l'annonce
                                            </Button>
                                        ) : (
                                            <Button
                                                size='small'
                                                variant='outlined'
                                                endIcon={<Done />}
                                                sx={{ cursor: 'default' }}
                                            >
                                                Annonce Répondue
                                            </Button>
                                        )
                                        }
                                    </CardActions>
                                    {/* } */}
                                </Card >
                            </Grid>
                            <Grid item xs={12} md={8.5} order={{ xs: 3, md: 2 }}>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        px: 4,
                                        py: 2,
                                        m: 2,
                                    }}
                                    data-index={2}
                                    ref={(el) => (targetRefs.current[2] = el)}
                                    className={`transition-opacity ease-in-out transform ${visibleItems.includes(2)
                                        ? "animate-slide-in-up" : "opacity-0 translate-y-10"
                                        }`}
                                >
                                    <CardHeader title={<Typography variant="h5" sx={{ fontSize: '1.2rem' }}>{ad.title}</Typography>} />
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={2}>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Typography variant="body2" sx={{ color: "green", marginRight: 1, fontWeight: 'bold' }}>
                                                        {currency === 'MAD' ? 'MAD' : 'FCFA'}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {currency === 'MAD' ? ad.price : ad.price * 60}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Scale fontSize="small" sx={{ color: "var(--secondary)", marginRight: 1 }} />
                                                    <Typography variant="body2">{ad.weight} kg</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
                                                <Typography variant='h6' component='span'>
                                                    Lieu
                                                </Typography>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Typography variant="body2" sx={{ color: 'GrayText' }}>Départ</Typography>
                                                    {ad.departureRelay &&
                                                        <Typography variant="body2">
                                                            { }  {ad.departureRelay.address},
                                                            {ad.departureRelay.city},
                                                            {ad.departureRelay.country}
                                                        </Typography>
                                                    }
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Typography variant="body2" sx={{ color: 'GrayText' }}>Arrivée</Typography>
                                                    {ad.arrivalRelay &&
                                                        <Typography variant="body2">
                                                            { }  {ad.arrivalRelay.address},
                                                            {ad.arrivalRelay.city},
                                                            {ad.arrivalRelay.country}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
                                                <Typography variant='h6' component='span'>
                                                    Dates
                                                </Typography>
                                                {ad.type === 'REQUEST' && [
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Entre le</Typography>
                                                        <Typography variant="body2">{ad.minDate}</Typography>
                                                    </Box>
                                                    ,
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Et le</Typography>
                                                        <Typography variant="body2">{ad.maxDate}</Typography>
                                                    </Box>
                                                ]}
                                                {ad.type === 'SALE' && [
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Départ</Typography>
                                                        <Typography variant="body2">{ad.departureDate}</Typography>
                                                    </Box>
                                                    ,
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Arrivée</Typography>
                                                        <Typography variant="body2">{ad.arrivalDate}</Typography>
                                                    </Box>
                                                    ,
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Délai de dépot de bagage(s)</Typography>
                                                        <Typography variant="body2">{ad.depositDeadline}</Typography>
                                                    </Box>
                                                ]}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
                                                <Typography variant='h6' component='span'>
                                                    Bagages
                                                </Typography>
                                                {ad.type === 'REQUEST' ?
                                                    <>
                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <Typography variant="body2" sx={{ color: 'GrayText' }}>Nombre</Typography>
                                                            <Typography variant="body2">{ad.luggageNumber}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <Typography variant="body2" sx={{ color: 'GrayText' }}>Description</Typography>
                                                            <Typography variant="body2">{ad.luggageDescription}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <Typography variant="body2" sx={{ color: 'GrayText' }}>Contenu des bagages</Typography>
                                                            {ad.adContents && ad.adContents.map((content) =>
                                                                <Typography variant="body2" key={content.id}>
                                                                    {content.designation}
                                                                    {ad.adContents[ad.adContents.length - 1].id !== content.id && `. `}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </>
                                                    :
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                        <Typography variant="body2" sx={{ color: 'GrayText' }}>Nombre maximum de bagages</Typography>
                                                        <Typography variant="body2">{ad.luggageNumber}</Typography>
                                                    </Box>
                                                }
                                            </Grid>

                                            {ad.note &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider orientation="horizontal" />
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
                                                        <Typography variant='body1' component='span'>Note</Typography>
                                                        <Typography variant='body2' component='span' color='GrayText'>{ad.note}</Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card >
                            </Grid>
                        </Grid>
                        <OfferCreateDialog setLoading={setLoading} ad={ad} openCreateOffer={openDialog} setOpenCreateOffer={setOpenDialog} setAd={setAd} />
                    </div >
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}

export default AdDetails
