import React, { createContext, useContext } from "react";
import { useLocalState } from "./useLocalStorage";

const CurrencyContext = createContext();

export const useCurrency = () => {
    return useContext(CurrencyContext);
}

export default function CurrencyProvider({ children }) {
    const [currency, setCurrency] = useLocalState("currency", "MAD");

    return (
        <CurrencyContext.Provider value={{ currency }}>
            {children}
        </CurrencyContext.Provider>
    );
};
