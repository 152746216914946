import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import ajax from '../../../services/ajax';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import showAlert from '../../../utils/ShowAlert';

const ResetPassword = ({ setLoading }) => {
    const location = useLocation();

    const token = new URLSearchParams(location.search).get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (password !== confirmPassword) {
            showAlert('Veuillez ressaisir le même mot de passe', 'error');
            return;
        }

        const request = {
            token,
            password
        }

        console.log(request.password);

        const result = await ajax('/api/password/reset', 'POST', null, request);
        if (result !== null) {
            setLoading(false);
            showAlert('Mot de passe réinitialisé avec succès', 'success');
            window.location.href = '/';
        }
        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 7,
                    marginBottom: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 3,
                    borderRadius: 3,
                    px: 5,
                    py: 5
                }}
                className='transition-opacity duration-300 ease-in-out animate-fade-in'
            >
                <IconButton
                    size="large" sx={{ m: 1 }}
                    LinkComponent="a" href="/"
                >
                    <Lock sx={{ width: 100, height: 80, mr: 2, color: 'var(--primary)' }} />
                </IconButton>
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                    Réinitialisation du mot de passe
                </Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 20 }}>
                    <TextField
                        required
                        fullWidth
                        label={"Nouveau mot de passe"}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                password &&
                                <InputAdornment position="end" sx={{ mr: 2 }}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        required
                        fullWidth
                        label={"Confirmer le nouveau mot de passe"}
                        name="confirmPassword"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                password &&
                                <InputAdornment position="end" sx={{ mr: 2 }}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        type='submit'
                    >
                        Confirmer
                    </Button>
                </form>
            </Box>
        </Container >
    );
};

export default ResetPassword;
