import React, { useEffect, useRef, useState } from 'react'
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack,
    FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material'
import { getUserById, updateUser, updateUserRole } from '../../../services/userService'
import { roleLabel, role_array } from '../../../data/userData'
import PhoneNumberField from '../../public_components/Layouts/PhoneNumberField'
import { useJwt } from '../../../utils/UserProvider';

const UserUpdateDialog = ({ setLoading, userId, openUpdateUser, setOpenUpdateUser, setUsers }) => {
    const { jwt } = useJwt();

    const initialState = {
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
    };
    const userRef = useRef();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(initialState);

    const updateError = (prop, value) => {
        setError(prev => ({
            ...error,
            [prop]: value
        }));
    }

    const updateUserProp = (prop, value) => {
        setUser(prev => ({
            ...prev,
            [prop]: value
        }))
        if (!value) {
            updateError(prop, "Ce champ est obligatoire");
        }
        else {
            updateError(prop, '');
        }
    }

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const userData = await getUserById(userId, jwt);
            if (userData !== null) {
                setUser(userData);
                userRef.current = userData;
            }
            setLoading(false);
        };
        if (jwt && userId) {
            fetchUser();
        }

    }, [userId, jwt, setLoading])

    const handleCloseUpdateUser = () => {
        setOpenUpdateUser(false);
        userRef.current && setUser(({ ...userRef.current }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const savedUser = await updateUser(user, jwt);
        const savedRoleUser = await updateUserRole(user, jwt);
        if (savedUser !== null && savedRoleUser !== null) {
            setUser({ ...savedRoleUser });
            setUsers(prev => {
                const index = prev.findIndex((item) => item.id === savedUser.id);
                return prev.map((user, i) => {
                    if (i === index)
                        return savedRoleUser;
                    else
                        return user;
                });
            })
            setOpenUpdateUser(false);
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openUpdateUser}
            onClose={handleCloseUpdateUser}
            className='transition-opacity duration-300 ease-in-out animate-fade-in'
        >
            <DialogTitle>Editer l'utilisateur</DialogTitle>
            <DialogContent>
                {user &&
                    <Stack spacing={2}>
                        <FormControl fullWidth required sx={{ my: 2 }} >
                            <InputLabel id="roles-label">Roles</InputLabel>
                            <Select
                                required
                                labelId='roles-label'
                                label="Roles"
                                displayEmpty
                                value={user.role}
                                onChange={(e) => updateUserProp('role', e.target.value)}
                                error={error.role !== ''}
                                helpertext={error.role !== '' ? error.role : undefined}
                            >
                                {[
                                    <MenuItem key={0} value={user.role}>
                                        {roleLabel(user.role)}
                                    </MenuItem>
                                ]}
                                {role_array.map((option) => (
                                    option.value !== user.role
                                    &&
                                    [
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ]
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            label="Prénom"
                            value={user.firstName}
                            onChange={(e) => updateUserProp("firstName", e.target.value)}
                        />
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            name="lastName"
                            label="Nom"
                            value={user.lastName}
                            onChange={(e) => updateUserProp("lastName", e.target.value)}
                            error={error.lastName !== ''}
                            helperText={error.lastName !== '' ? error.lastName : undefined}
                        />
                        <PhoneNumberField
                            phone={user.phone}
                            update={updateUserProp}
                        />
                    </Stack>

                }
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseUpdateUser}>
                    Annuler
                </Button>
                <Button variant='contained' onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserUpdateDialog