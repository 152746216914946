import { Box, Button, Container, CssBaseline, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { LockOpen } from '@mui/icons-material';
import { activateReactivate, updateUserEmail } from '../../../services/userService';
import showAlert from '../../../utils/ShowAlert';

const ActivateInactive = ({ setLoading }) => {

    const emailRef = useRef();

    const urlParams = new URLSearchParams(window.location.search)
    const emailFromUrl = urlParams.get("email") || "";
    emailRef.current = emailFromUrl;

    const [userRequest, setUserRequest] = useState({ email: emailFromUrl, oldEmail: emailFromUrl });
    const [error, setError] = useState('');

    const handleChangeEmail = (value) => {
        setUserRequest({ ...userRequest, email: value });

        const regexEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
        setError(regexEmail.test(value) ? "" : "Entrez une adresse email valide !");
    }

    const handleSubmitActivate = async (e) => {
        setLoading(true);
        e.preventDefault();
        // console.error(userRequest.email);
        const result = await activateReactivate(userRequest);
        if (result !== null) {
            showAlert("Activez votre compte en cliquant sur le lien envoyé par mail !", 'success');
        }
        setLoading(false);
    }

    const handleSubmitUpdateEmail = async (e) => {
        setLoading(true);
        e.preventDefault();
        // console.log(userRequest.oldEmail)
        const result = await updateUserEmail(userRequest);
        if (result !== null) {
            showAlert("Activez votre compte en cliquant sur le lien envoyé par mail !", 'success');
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 200);

        return () => clearTimeout(timer);
    }, [setLoading]);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            marginTop: 5,
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 3,
                            borderRadius: 3,
                            px: 6,
                            py: 2,
                        }}
                    >
                        <IconButton
                            size="large" sx={{ m: 1 }}
                            LinkComponent="a" href="/"
                        >
                            <LockOpen sx={{ width: 100, height: 80, mr: 2, color: 'var(--primary)' }} />
                        </IconButton>
                        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                            Activer ou Réactiver votre compte
                        </Typography>
                        <form
                            onSubmit={handleSubmitActivate}
                            noValidate
                        >
                            <p>Un lien vous a été envoyé par mail, cliquez dessus afin d'activer votre compte.</p>
                            <TextField
                                required
                                disabled
                                fullWidth
                                margin="normal"
                                label="Adresse email"
                                name="email"
                                value={userRequest.oldEmail}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Renvoyer le lien d'activation
                            </Button>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <center>OU</center>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            marginTop: 5,
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 3,
                            borderRadius: 3,
                            px: 6,
                            py: 2
                        }}
                    >
                        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                            Modifier votre adresse email
                        </Typography>

                        <Divider />
                        <form
                            onSubmit={handleSubmitUpdateEmail}
                            noValidate
                        >
                            <p>Votre adresse email n'est pas correcte ? Modifier la ci-dessous :</p>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                label="Nouvelle adresse email"
                                name="email"
                                value={userRequest.email}
                                onChange={(e) => handleChangeEmail(e.target.value)}
                                error={error !== ''}
                                helperText={error}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer et envoyer le lien d'activation
                            </Button>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Container >
    )
}

export default ActivateInactive